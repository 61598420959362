import graphIcon from "../../../../../assets/images/graph-icon.svg";
import { motion } from "framer-motion";
import "./styles.scss";

interface FeatureCardProps {
  title: string;
  description: string;
}

const FeatureCard = ({ title, description }: FeatureCardProps) => {
  return (
    <motion.div
      layout
      className="feature-card"
      initial={{ opacity: 0, scale: 0, x: 100, y: -200 }}
      whileInView={{ opacity: 1, scale: 1, x: 0, y: 0, rotateY: 360 }}
      viewport={{ once: true }}
      transition={{ duration: 1.2 }}
    >
      <div className="card-animation-circle"></div>
      <img src={graphIcon} width={48} alt="graph-icon" className="graph-icon" />
      <p className="feature-card-title">{title}</p>
      <p className="feature-card-description">{description}</p>
    </motion.div>
  );
};

export default FeatureCard;
