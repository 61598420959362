import { useCallback, useEffect, useLayoutEffect } from "react";
import { InsiderGraph } from "../../components/common";
import { useLazyGetTopTenInsidersQuery } from "../../services/insider";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useAuth } from "@clerk/clerk-react";
import { Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "../../hooks";
import { Response } from "../../types";

const Dashboard = () => {
  let hasRun = false;
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const success = searchParams.get("success");
  const canceled = searchParams.get("canceled");
  const { successToast, errorToast } = useToast();
  const { getToken, signOut } = useAuth();
  const [trigger, result] = useLazyGetTopTenInsidersQuery();
  const { data, isLoading, isError, error } = result;

  const fetchToken = useCallback(async () => {
    const token = await getToken();
    if (token) {
      trigger({
        token,
      });
    }
  }, []);

  useEffect(() => {
    if (hasRun) return;
    hasRun = true;
    if (success === "true") {
      successToast("Success", "Payment Successfull");
      navigate("/dashboard");
    } else if (canceled === "true") {
      errorToast("Error", "Payment Failed");
      navigate("/dashboard");
    }
  }, [success, canceled]);

  useEffect(() => {
    if (isError && error) {
      const fetchBaseQueryError = error as FetchBaseQueryError;
      if (fetchBaseQueryError.data) {
        const { message } = fetchBaseQueryError.data as Response;
        errorToast("Error", message || "Something went wrong");
      } else {
        errorToast("Error", "Something went wrong");
      }
      if (fetchBaseQueryError.status === 401) signOut();
    }
  }, [isError, error]);

  useLayoutEffect(() => {
    fetchToken();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <InsiderGraph title="Top 10 Tokens called by Insiders" currentPage={1} data={data?.data.data || []} dataCount={10} />
      )}
    </>
  );
};

export default Dashboard;
