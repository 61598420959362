import { Flex } from "antd";
import starImg from "../../../../assets/images/star-img.png";
import homeImgHeader from "../../../../assets/images/home-bg-img-header.svg";
import homeImgGraph from "../../../../assets/images/home-bg-img-graph.svg";
import homeImgFacts from "../../../../assets/images/home-bg-img-facts.svg";
import { motion } from "framer-motion";
import { GetStartedButton } from "../../../common";

const HomeHeroSection = () => {
  const spring = {
    type: "spring",
    damping: 8,
    stiffness: 28,
  };

  return (
    <Flex className="section-container hero-section" justify="space-between" align="center" gap={40}>
      <motion.div
        className="section-left-column hero-section-text"
        initial={{ opacity: 0, scale: 0, transformOrigin: "left center" }}
        animate={{ opacity: 1, scale: 1 }}
        transition={spring}
      >
        <h1 className="hero-section-title">
          Track trendspotters to unlock key on-chain insights
          <img src={starImg} alt="start-img" className="hero-section-start-icon" />
        </h1>
        <p className="section-description hero-section-description">We've mastered the chaos of crypto - so you dont have to.</p>
        <GetStartedButton className="section-get-started-btn" />
      </motion.div>
      <Flex className="section-right-column" style={{ position: "relative" }} vertical align="end">
        <motion.div
          layout
          initial={{ opacity: 0.5, x: 100, y: -100 }}
          animate={{ opacity: 1, x: 0, y: 0, rotateY: 360 }}
          transition={spring}
        >
          <Flex vertical align="center" gap={10}>
            <img src={homeImgHeader} className="hero-section-header-img" alt="home-bg-img" />
            <img src={homeImgGraph} className="hero-section-graph-img" alt="home-bg-img" />
          </Flex>
        </motion.div>
        <motion.div layout initial={{ opacity: 0.5 }} animate={{ opacity: 1, rotateY: -360 }} transition={spring}>
          <img src={homeImgFacts} className="hero-section-facts-img" alt="home-bg-img" />
        </motion.div>
      </Flex>
    </Flex>
  );
};

export default HomeHeroSection;
