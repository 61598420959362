import { Flex } from "antd";
import { userReviews } from "../../../../constants";
import "./styles.scss";

interface UserReviewCardProps {
  review: (typeof userReviews)[number];
  index: number;
}

const UserReviewCard = ({ review, index }: UserReviewCardProps) => {
  const { name, comment, img } = review;
  const isOdd = () => {
    return index % 2 !== 0;
  };

  return (
    <Flex className="user-review-card" vertical gap={24} style={{ marginTop: isOdd() ? "60px" : "0px" }}>
      <Flex align="center" gap={16}>
        <img src={img} width={48} alt="avatar" className="user-review-card-avatar" />
        <Flex className="user-review-card-price-container" vertical>
          <p className="user-review-card-name">{name}</p>
        </Flex>
      </Flex>
      <p className="user-review-card-comment">{comment?.slice(0, 250)}</p>
    </Flex>
  );
};

export default UserReviewCard;
