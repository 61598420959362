import { combineReducers } from "redux";
import sidebarReducer from "./sidebar.slice";
import loadingReducer from "./loading.slice";
import insiderReducer from "./insider.slice";
import spyReducer from "./spy.slice";
import { insiderApi } from "../services/insider";
import { planApi } from "../services/plan";
import { subscriptionApi } from "../services/subscription";
import { spyMenuApi } from "../services/spyMenu";

const rootReducer = combineReducers({
  sidebars: sidebarReducer,
  loading: loadingReducer,
  insiders: insiderReducer,
  spies: spyReducer,
  [insiderApi.reducerPath]: insiderApi.reducer,
  [planApi.reducerPath]: planApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [spyMenuApi.reducerPath]: spyMenuApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
