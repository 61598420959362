import { Flex, Popover, message } from "antd";
import dotsIcon from "../../../../assets/images/three-dots.svg";
import copyIcon from "../../../../assets/images/copy-icon.svg";
import "./styles.scss";
import { MouseEvent, useState } from "react";

interface CopyPopoverProps {
  text: string;
}

const CopyPopover = (props: CopyPopoverProps) => {
  const [open, setOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  const { text } = props;

  const handleCopyClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      success();
      setOpen(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Popover
        content={
          <Flex vertical gap={10}>
            <p>{text}</p>
            <img src={copyIcon} className="cursor-pointer" width={16} onClick={handleCopyClick} />
          </Flex>
        }
        trigger="click"
        color="#1A202C"
        rootClassName="copy-popover"
        open={open}
        onOpenChange={setOpen}
      >
        <Flex
          className="dots"
          justify="center"
          align="center"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <img src={dotsIcon} />
        </Flex>
      </Popover>
    </>
  );
};
export default CopyPopover;
