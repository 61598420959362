import { Flex } from "antd";
import SectionHeader from "../../../common/SectionHeader";
import { howItWorks } from "../../../../constants";
import HowItWorksCard from "./HowItWorksCard";
import "./styles.scss";

const HowItWorksSection = () => {
  return (
    <Flex className="section-container how-it-works-section" vertical align="center" gap={60}>
      <SectionHeader
        title="How It Works"
        description="At Blockspy, we believe in continuous improvement, and we value your input. This is your platform, and we want it to meet your needs."
      />
      <Flex className="how-it-works-section-cards" gap={24}>
        {howItWorks.map((mission, index) => (
          <HowItWorksCard key={index} index={index} title={mission.title} description={mission.description} />
        ))}
      </Flex>
    </Flex>
  );
};

export default HowItWorksSection;
