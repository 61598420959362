import { FiltersType } from "../types";
export const filtersInitialState: FiltersType = {
  blockchain: "",
  token_symbol: "",
  net_flow_usd_from: "",
  net_flow_usd_to: "",
  sell_amount_usd_from: "",
  sell_amount_usd_to: "",
  buy_amount_usd_from: "",
  buy_amount_usd_to: "",
  sellers: "",
  buyers: "",
};
