// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bg-img.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-layout {
  padding: 32px;
  min-height: calc(100vh - 64px);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.dashboard-layout .dashboard-layout-sidebar {
  position: fixed;
  padding: 32px;
  top: 0;
  bottom: 0;
  left: 0;
}
.dashboard-layout .dashboard-layout-content {
  margin-left: 250px;
  width: calc(100% - 250px);
  padding-left: 20px;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .dashboard-layout .dashboard-layout-sidebar {
    display: none;
  }
  .dashboard-layout .dashboard-layout-content {
    margin-left: 0;
    padding-left: 0px;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .dashboard-layout {
    padding: 16px;
    min-height: calc(100vh - 32px);
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/DashboardLayout/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,yDAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,kBAAA;AADF;AAGE;EACE,eAAA;EACA,aAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;AADJ;AAIE;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;AAFJ;AAKE;EACE;IACE,aAAA;EAHJ;EAKE;IACE,cAAA;IACA,iBAAA;IACA,WAAA;EAHJ;AACF;AAME;EAnCF;IAoCI,aAAA;IACA,8BAAA;EAHF;AACF","sourcesContent":["@import \"../../styles/variables\";\n\n.dashboard-layout {\n  padding: 32px;\n  min-height: calc(100vh - 64px);\n  background-image: url(\"../../assets/images/bg-img.svg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  position: relative;\n\n  .dashboard-layout-sidebar {\n    position: fixed;\n    padding: 32px;\n    top: 0;\n    bottom: 0;\n    left: 0;\n  }\n\n  .dashboard-layout-content {\n    margin-left: 250px;\n    width: calc(100% - 250px);\n    padding-left: 20px;\n    z-index: 0;\n  }\n\n  @media screen and (max-width: $md-breakpoint) {\n    .dashboard-layout-sidebar {\n      display: none;\n    }\n    .dashboard-layout-content {\n      margin-left: 0;\n      padding-left: 0px;\n      width: 100%;\n    }\n  }\n\n  @media screen and (max-width: $sm-breakpoint) {\n    padding: 16px;\n    min-height: calc(100vh - 32px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
