import { Flex } from "antd";
import { OutlineButton, PasswordInput } from "../../../ui-elements";
import "./styles.scss";
import { useUser } from "@clerk/clerk-react";
import { Formik, FormikHelpers } from "formik";
import { updatePasswordFormValidationSchema } from "../../../../validation-schemas";
import ErrorText from "../../../common/ErrorText";
import { useToast } from "../../../../hooks";
import { useState } from "react";

const PasswordSettings = () => {
  const [loading, setLoading] = useState(false);
  const { successToast, errorToast } = useToast();
  const { isLoaded, user } = useUser();

  const initialValues = { currentPassword: "", newPassword: "" };

  const updatePassword = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    if (!isLoaded) {
      return;
    }
    try {
      setLoading(true);
      const { resetForm } = actions;
      await user?.updatePassword(values);
      successToast("Successful", "Password updated successfully.");
      resetForm();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      errorToast("Failed", error.errors[0].longMessage || error.message);
      console.error("error", error.errors || error.message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={updatePasswordFormValidationSchema} onSubmit={updatePassword}>
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Flex className="user-settings-container" vertical gap={24}>
            <p className="dashboard-content-section-title">Update Password</p>
            <Flex className="user-settings-inputs-container" gap={24}>
              <Flex className="user-settings-container-column" vertical gap={12}>
                <Flex className="user-settings-container-column" align="center" gap={60}>
                  <p className="user-settings-container-input-label">Current Password</p>
                  <PasswordInput
                    name="currentPassword"
                    value={values.currentPassword}
                    status={errors.currentPassword && touched.currentPassword ? "error" : ""}
                    className="user-settings-container-input"
                    placeholder="Current Password"
                    onChange={handleChange}
                  />
                </Flex>
                {errors.currentPassword && touched.currentPassword && <ErrorText text={errors.currentPassword} />}
              </Flex>
              <Flex className="user-settings-container-column" vertical gap={12}>
                <Flex className="user-settings-container-column" align="center" gap={60}>
                  <p className="user-settings-container-input-label">New Password</p>
                  <PasswordInput
                    name="newPassword"
                    value={values.newPassword}
                    status={errors.newPassword && touched.newPassword ? "error" : ""}
                    className="user-settings-container-input"
                    placeholder="New Password"
                    onChange={handleChange}
                  />
                </Flex>
                {errors.newPassword && touched.newPassword && <ErrorText text={errors.newPassword} />}
              </Flex>
            </Flex>
            <OutlineButton className="save-btn" htmlType="submit" loading={loading}>
              Save Changes
            </OutlineButton>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default PasswordSettings;
