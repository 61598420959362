// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bg-img.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-layout {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .user-layout {
    background-size: contain;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/UserLayout/styles.scss"],"names":[],"mappings":"AAEA;EACE,yDAAA;EACA,sBAAA;AADF;AAEE;EAHF;IAII,wBAAA;EACF;AACF","sourcesContent":["@import \"../../styles/variables\";\n\n.user-layout {\n  background-image: url(\"../../assets/images/bg-img.svg\");\n  background-size: cover;\n  @media screen and (max-width: $md-breakpoint) {\n    background-size: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
