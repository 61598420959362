// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
  .how-it-works-section .how-it-works-section-cards {
    flex-wrap: wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/screens/Feedback/HowItWorksSection/styles.scss"],"names":[],"mappings":"AAGE;EACE;IACE,eAAA;EAFJ;AACF","sourcesContent":["@import \"../../../../styles/variables\";\n\n.how-it-works-section {\n  @media screen and (max-width: $md-breakpoint) {\n    .how-it-works-section-cards {\n      flex-wrap: wrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
