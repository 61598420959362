import { Flex } from "antd";
import { Logo } from "../../../common";
import { useSignUp } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import ErrorText from "../../../common/ErrorText";
import { Button, Input } from "../../../ui-elements";
import { otpFormValidationSchema } from "../../../../validation-schemas";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../../../../slices/loading.slice";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../../../slices";
import { useToast } from "../../../../hooks";
import { SetStateAction } from "react";

interface OtpFormProps {
  setShowOtpForm: React.Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const OtpForm = ({ setShowOtpForm, loading, startLoading, stopLoading }: OtpFormProps) => {
  const { successToast, errorToast } = useToast();
  const { isLoaded, signUp, setActive } = useSignUp();
  const navigate = useNavigate();

  const initialValues = { otp: "" };

  const handleOtpVerification = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      const { resetForm } = actions;
      const response = await signUp.attemptEmailAddressVerification({
        code: values.otp,
      });

      if (response.status === "complete") {
        resetForm();
        setTimeout(() => {
          setActive({ session: response.createdSessionId });
          setShowOtpForm(false);
        }, 3000);
        successToast(
          "Signup Successful",
          "You have successfully verified your email address. Please wait while we redirect you to your dashboard.",
        );
      } else {
        errorToast("Signup Failed", "");
        console.log(response);
      }
      stopLoading();
    } catch (err: any) {
      errorToast("Signup Failed", err.errors[0].longMessage || err.message);
      stopLoading();
      console.error("error", err.errors || err.message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={otpFormValidationSchema} onSubmit={handleOtpVerification}>
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <form className="auth-form" onSubmit={handleSubmit}>
          <Flex vertical gap={100}>
            <Logo />
            <Flex vertical gap={32}>
              <h1 color="white" className="auth-form-title">
                Verify Email Code
              </h1>
              <Input
                name="otp"
                value={values.otp}
                status={errors.otp && touched.otp ? "error" : ""}
                autoFocus
                placeholder="Enter your otp"
                onChange={handleChange}
              />
              {errors.otp && touched.otp && <ErrorText text={errors.otp} />}
              <Button htmlType="submit" loading={loading}>
                Verify
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      startLoading,
      stopLoading,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpForm);
