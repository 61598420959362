import * as yup from "yup";

export const otpFormValidationSchema = yup.object({
  otp: yup
    .string()
    .required("Please provide the code.")
    .test("len", "Must be exactly 6 digits", (otp: string) => {
      return otp.length === 6 && /^\d{6}$/.test(otp);
    }),
});
