import { Dropdown as AntdDropdown, DropdownProps } from "antd";
import "./styles.scss";

const Dropdown = (props: DropdownProps) => {
  const { children } = props;

  return <AntdDropdown {...props}>{children}</AntdDropdown>;
};

export default Dropdown;
