export const howItWorks = [
  {
    title: "Feature Requests",
    description:
      "Have an idea for a new feature or improvement? We want to hear it! Tell us what you envision, and how it can enhance your Blockspy experience.",
  },
  {
    title: "General Feedback",
    description: `Whether it's praise, constructive criticism, or a simple "thank you," your feedback is invaluable. Let us know what you like, what can be better, and how we can serve you best.`,
  },
  {
    title: "Bug Reports",
    description:
      "Spotted a bug or glitch? Help us maintain a smooth experience by reporting any issues you encounter. Be as detailed as possible so we can address it swiftly.",
  },
];
