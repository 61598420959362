import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SpyMenuType } from "../types";

interface ReturnType {
  data: {
    data: SpyMenuType;
  };
}

interface PayloadType {
  token: string;
}

export const spyMenuApi = createApi({
  reducerPath: "spyMenuApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
  }),
  endpoints: builder => ({
    getSpyMenu: builder.query<ReturnType, PayloadType>({
      query: ({ token }) => {
        return {
          url: "spy-menu",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useLazyGetSpyMenuQuery } = spyMenuApi;
