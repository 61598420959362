// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.who-we-are-section .who-we-are-section-text .section-title,
.who-we-are-section .who-we-are-section-text .section-description {
  text-align: left;
}
.who-we-are-section .who-we-are-section-text .section-description {
  line-height: 26px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/WhoWeAreSection/styles.scss"],"names":[],"mappings":"AAII;;EAEE,gBAAA;AAHN;AAKI;EACE,iBAAA;AAHN","sourcesContent":["@import \"../../../styles/variables\";\n\n.who-we-are-section {\n  .who-we-are-section-text {\n    .section-title,\n    .section-description {\n      text-align: left;\n    }\n    .section-description {\n      line-height: 26px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
