import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { insiderApi } from "./services/insider";
import { setupListeners } from "@reduxjs/toolkit/query";
import { planApi } from "./services/plan";
import { subscriptionApi } from "./services/subscription";
import { spyMenuApi } from "./services/spyMenu";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENVIRONMENT === "dev",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(insiderApi.middleware)
      .concat(planApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(spyMenuApi.middleware),
});

setupListeners(store.dispatch);

export default store;
