import { Input } from "antd";
import "./styles.scss";
import { TextAreaProps } from "antd/es/input";

const TextArea = (props: TextAreaProps) => {
  const { TextArea: AntdTextArea } = Input;
  return (
    <div className="custom-text-area">
      <AntdTextArea {...props} />
    </div>
  );
};

export default TextArea;
