import { Flex } from "antd";
import Logo from "../../../common/Logo";
import { Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import Input from "../../../ui-elements/Input";
import emailIcon from "../../../../assets/images/mail-icon.svg";
import passwordIcon from "../../../../assets/images/key-icon.svg";
import PasswordInput from "../../../ui-elements/PasswordInput";
import Button from "../../../ui-elements/Button";
import Checkbox from "../../../ui-elements/Checkbox";
import { loginFormValidationSchema } from "../../../../validation-schemas";
import ErrorText from "../../../common/ErrorText";
import { useSignIn } from "@clerk/clerk-react";
import { RootState } from "../../../../slices";
import { startLoading, stopLoading } from "../../../../slices/loading.slice";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { useToast } from "../../../../hooks";
import { OAuthStrategy } from "@clerk/types";
import { Spin } from "antd";
import { useState } from "react";
interface LoginFormProps {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const LoginForm = ({ loading, startLoading, stopLoading }: LoginFormProps) => {
  const { successToast, errorToast } = useToast();
  const { isLoaded, signIn, setActive } = useSignIn();

  // Get email and password from cookies
  const savedEmail = Cookies.get("email");
  const savedPassword = Cookies.get("password");
  const savedFlag = Cookies.get("rememberMe") === "true" ? true : false;
  const [waitingForGoogle, setWaitingForGoogle] = useState(false);

  const initialValues = {
    email: savedEmail || "",
    password: savedPassword || "",
    rememberMe: savedFlag || false,
  };

  const handleLogin = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    const { resetForm } = actions;
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      const { email, password } = values;
      const response = await signIn.create({ identifier: email, password });
      if (response.status === "complete") {
        setActive({ session: response.createdSessionId });

        if (values.rememberMe) {
          Cookies.set("email", values.email, { expires: 7 });
          Cookies.set("password", values.password, { expires: 7 });
          Cookies.set("rememberMe", "true", { expires: 7 });
        } else {
          Cookies.remove("email");
          Cookies.remove("password");
          Cookies.remove("rememberMe");
        }

        resetForm();
        successToast("Login Successful", "You have successfully logged in.");
      } else {
        errorToast("Login Failed", "");
        console.log(response);
      }
      stopLoading();
    } catch (err: any) {
      errorToast("Login Failed", err.errors[0].longMessage || err.message);
      stopLoading();
      console.error("error", err.errors || err.message);
    }
  };
  const signInWith = async (strategy: OAuthStrategy) => {
    setWaitingForGoogle(true);
    try {
      return await signIn?.authenticateWithRedirect({
        strategy,
        redirectUrl: "/dashboard",
        redirectUrlComplete: "/dashboard",
      });
    } catch (error) {
      console.error("Sign-in error:", error);
    } finally {
      setTimeout(() => {
        setWaitingForGoogle(false);
      }, 2000);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={loginFormValidationSchema} onSubmit={handleLogin}>
      {({ handleSubmit, handleChange, errors, values, touched }) => (
        <form className="auth-form" onSubmit={handleSubmit}>
          <Flex vertical gap={100}>
            <Logo />
            <Flex vertical gap={32}>
              <Flex vertical gap={4}>
                <h1 color="white" className="auth-form-title">
                  Welcome Back to Blockspy
                </h1>
                <p className="auth-form-subtitle">Master the chaos of crypto.</p>
              </Flex>
              <Flex vertical gap={16}>
                <Input
                  name="email"
                  value={values.email}
                  status={errors.email && touched.email ? "error" : ""}
                  autoFocus
                  placeholder="Enter your email"
                  prefix={<img src={emailIcon} />}
                  onChange={handleChange}
                />
                {errors.email && touched.email && <ErrorText text={errors.email} />}
                <PasswordInput
                  name="password"
                  value={values.password}
                  status={errors.password && touched.password ? "error" : ""}
                  placeholder="Enter your password"
                  prefix={<img src={passwordIcon} />}
                  onChange={handleChange}
                />
                {errors.password && touched.password && <ErrorText text={errors.password} />}
                <Flex justify="space-between">
                  <Checkbox name="rememberMe" checked={values.rememberMe} onChange={handleChange}>
                    Remember Me
                  </Checkbox>
                  <Link to="/forget-password" className="link">
                    Forget Password?
                  </Link>
                </Flex>
              </Flex>
              <Button htmlType="submit">{loading ? <Spin /> : <p> Login</p>}</Button>
              <Button className="google-btn" onClick={() => signInWith("oauth_google")}>
                {waitingForGoogle ? <Spin /> : <p> Signin with Google</p>}
              </Button>
              <Flex vertical align="center" gap={4}>
                <p className="auth-form-footer-text">Don't have an account?</p>
                <Link to="/signup" className="link">
                  Sign Up Here
                </Link>
                <p className="auth-form-footer-text">Join Blockspy for comprehensive blockchain data analytics.</p>
              </Flex>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      startLoading,
      stopLoading,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
