import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Insider } from "../types";

interface ReturnType {
  data: {
    data: Insider[];
    totalCount: number;
  };
}

interface PayloadType {
  page?: number;
  pageSize?: number;
  tableName?: string | undefined;
  token: string;
  filters?: any;
}

const DEFAULT_INSIDER_NAME = "shrap";
const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const insiderApi = createApi({
  reducerPath: "insiderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
  }),
  endpoints: builder => ({
    getTopTenInsiders: builder.query<ReturnType, PayloadType>({
      query: ({ token }) => {
        return {
          url: "insider/top-ten",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getOneDayInsiders: builder.query<ReturnType, PayloadType>({
      query: ({ page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, tableName = DEFAULT_INSIDER_NAME, token, filters }) => {
        return {
          url: `insider/one-day/${tableName}`,
          params: { page, pageSize, tableName, filters },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getThreeDaysInsiders: builder.query<ReturnType, PayloadType>({
      query: ({ page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, tableName = DEFAULT_INSIDER_NAME, token, filters }) => {
        return {
          url: `insider/three-days/${tableName}`,
          params: { page, pageSize, tableName, filters },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getSevenDaysInsiders: builder.query<ReturnType, PayloadType>({
      query: ({ page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, tableName = DEFAULT_INSIDER_NAME, token, filters }) => {
        return {
          url: `insider/seven-days/${tableName}`,
          params: { page, pageSize, tableName, filters },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getFourWeeksInsiders: builder.query<ReturnType, PayloadType>({
      query: ({ page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, tableName = DEFAULT_INSIDER_NAME, token, filters }) => {
        return {
          url: `insider/four-weeks/${tableName}`,
          params: { page, pageSize, tableName, filters },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetTopTenInsidersQuery,
  useLazyGetOneDayInsidersQuery,
  useLazyGetThreeDaysInsidersQuery,
  useLazyGetSevenDaysInsidersQuery,
  useLazyGetFourWeeksInsidersQuery,
} = insiderApi;
