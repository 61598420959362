import { forgetPasswordFormValidationSchema } from "./forgetPasswordSchema";
import { loginFormValidationSchema } from "./loginFormSchema";
import { signupFormValidationSchema } from "./signupFormSchema";
import { otpFormValidationSchema } from "./otpFormSchema";
import { resetPasswordFormValidationSchema } from "./resetPasswordSchema";
import { updatePasswordFormValidationSchema } from "./updatePasswordSchema";
import { updateEmailFormValidationSchema } from "./updateEmailSchema";
import { feedbackFormSchema } from "./feedbackFormSchema";

export {
  loginFormValidationSchema,
  signupFormValidationSchema,
  forgetPasswordFormValidationSchema,
  otpFormValidationSchema,
  resetPasswordFormValidationSchema,
  updatePasswordFormValidationSchema,
  updateEmailFormValidationSchema,
  feedbackFormSchema,
};
