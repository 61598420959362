import { Flex } from "antd";
import { Logo } from "../../../common";
import { useSignIn } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import passwordIcon from "../../../../assets/images/key-icon.svg";
import ErrorText from "../../../common/ErrorText";
import { Button, Input, PasswordInput } from "../../../ui-elements";
import { resetPasswordFormValidationSchema } from "../../../../validation-schemas";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../../../../slices/loading.slice";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../../../slices";
import { useToast } from "../../../../hooks";

interface ResetPasswordFormProps {
  setShowResetPasswordForm: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const ResetPasswordForm = ({ setShowResetPasswordForm, loading, startLoading, stopLoading }: ResetPasswordFormProps) => {
  const { successToast, errorToast } = useToast();
  const { isLoaded, signIn, setActive } = useSignIn();
  const navigate = useNavigate();

  const initialValues = { otp: "", password: "", confirmPassword: "" };

  const resetPassword = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      const { resetForm } = actions;
      const { otp, password } = values;
      const response = await signIn.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code: otp,
        password,
      });

      if (response.status === "complete") {
        successToast("Successful", "You have successfully reset your password.");
        resetForm();
        setActive({ session: response.createdSessionId });
        setTimeout(() => {
          setShowResetPasswordForm(false);
          navigate("/dashboard");
        }, 1000);
      } else {
        errorToast("Failed", "");
        console.log(response);
      }
      stopLoading();
    } catch (err: any) {
      errorToast("Failed", err.errors[0].longMessage || err.message);
      stopLoading();
      console.error("error", err.errors || err.message);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={resetPasswordFormValidationSchema} onSubmit={resetPassword}>
      {({ handleSubmit, handleChange, handleBlur, errors, touched }) => (
        <form className="auth-form" onSubmit={handleSubmit}>
          <Flex vertical gap={100}>
            <Logo />
            <Flex vertical gap={32}>
              <Flex vertical gap={4}>
                <h1 color="white" className="auth-form-title">
                  Reset Your Password
                </h1>
                <p className="auth-form-subtitle">Easily recover access to your Blockspy account.</p>
              </Flex>
              <Input
                name="otp"
                type="number"
                status={errors.otp && touched.otp ? "error" : ""}
                placeholder="Enter your otp"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.otp && touched.otp && <ErrorText text={errors.otp} />}
              <PasswordInput
                name="password"
                status={errors.password && touched.password ? "error" : ""}
                placeholder="Enter new password"
                prefix={<img src={passwordIcon} />}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password && <ErrorText text={errors.password} />}
              <PasswordInput
                name="confirmPassword"
                status={errors.confirmPassword && touched.confirmPassword ? "error" : ""}
                placeholder="Confirm new password"
                prefix={<img src={passwordIcon} />}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.confirmPassword && touched.confirmPassword && <ErrorText text={errors.confirmPassword} />}
              <Button htmlType="submit" loading={loading}>
                Reset Password
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      startLoading,
      stopLoading,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
