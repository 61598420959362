import { Flex } from "antd";
import Logo from "../../../common/Logo";
import { Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import Input from "../../../ui-elements/Input";
import emailIcon from "../../../../assets/images/mail-icon.svg";
import Button from "../../../ui-elements/Button";
import Checkbox from "../../../ui-elements/Checkbox";
import { forgetPasswordFormValidationSchema } from "../../../../validation-schemas";
import ErrorText from "../../../common/ErrorText";
import { useSignIn } from "@clerk/clerk-react";
import { useCallback, useState } from "react";
import ResetPasswordForm from "../ResetPasswordForm";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../../../../slices/loading.slice";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../../../slices";
import { useToast } from "../../../../hooks";
import { DisclaimerModal } from "../../../common";

interface ForgetPasswordFormProps {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const ForgetPasswordForm = ({ loading, startLoading, stopLoading }: ForgetPasswordFormProps) => {
  const [open, setOpen] = useState(false);
  const { successToast, errorToast } = useToast();
  const { isLoaded, signIn } = useSignIn();
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const initialValues = { email: "", agreeToTerms: false };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const sendResetOtp = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      const { resetForm } = actions;
      const { email } = values;
      await signIn.create({
        strategy: "reset_password_email_code",
        identifier: email,
      });
      successToast("Successful", "We have sent you an email with a verification code.");
      resetForm();
      setShowResetPasswordForm(true);
      stopLoading();
    } catch (err: any) {
      errorToast("Failed", err.errors[0].longMessage || err.message);
      stopLoading();
      console.error("error", err.errors);
    }
  };

  return (
    <>
      {!showResetPasswordForm && (
        <Formik
          initialValues={{ email: "", agreeToTerms: false }}
          validationSchema={forgetPasswordFormValidationSchema}
          onSubmit={sendResetOtp}
        >
          {({ handleSubmit, handleChange, errors, touched }) => (
            <form className="auth-form" onSubmit={handleSubmit}>
              <Flex vertical gap={100}>
                <Logo />
                <Flex vertical gap={32}>
                  <Flex vertical gap={4}>
                    <h1 color="white" className="auth-form-title">
                      Reset Your Password
                    </h1>
                    <p className="auth-form-subtitle">Easily recover access to your Blockspy account.</p>
                  </Flex>
                  <Flex vertical gap={16}>
                    <Input
                      name="email"
                      status={errors.email && touched.email ? "error" : ""}
                      placeholder="Enter your registered email"
                      autoFocus
                      prefix={<img src={emailIcon} />}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email && <ErrorText text={errors.email} />}
                    <Flex gap={4}>
                      <Checkbox name="agreeToTerms" onChange={handleChange} />
                      <p className="auth-form-tos-text">
                        I agree to the <a onClick={() => setOpen(true)}>Terms and Conditions</a>
                      </p>
                    </Flex>
                    {errors.agreeToTerms && touched.agreeToTerms && <ErrorText text={errors.agreeToTerms} />}
                  </Flex>
                  <Button htmlType="submit" loading={loading}>
                    Send Reset Link
                  </Button>
                  <Flex vertical align="center" gap={4}>
                    <p className="auth-form-footer-text">Don't have an account?</p>
                    <Link to="/signup" replace className="link">
                      Sign Up Here
                    </Link>
                    <p className="auth-form-footer-text">Join Blockspy for comprehensive blockchain data analytics.</p>
                  </Flex>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      )}
      <DisclaimerModal open={open} handleClose={handleClose} />
      {showResetPasswordForm && <ResetPasswordForm setShowResetPasswordForm={setShowResetPasswordForm} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      startLoading,
      stopLoading,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordForm);
