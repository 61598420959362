export const monthlyPricingPlans = [
  {
    name: "Scout",
    price: "Free",
    btnText: "Select Explorer",
    offerings: ["Daily Data Updates", "1d & 3d Spy Token Buy/Sell Data", "Advanced Charting Tools", "User-Friendly Interface"],
  },
  {
    name: "Explorer",
    price: "$49",
    btnText: "Select Professional",
    offerings: ["Everything in Scout", "7d, 4w Spy Token Buy/Sell Data", "NFT holdings for spies"],
  },
  {
    name: "Secret Agent",
    price: "$79",
    btnText: "Select Professional",
    offerings: ["Everything in Explorer", "NFT trades for all spy groups 1d, 3d, 7d, 4w", "NFT spies token trade tracking"],
  },
];

export const annualPricingPlans = [
  {
    name: "Enterprise Plan",
    description: "",
    img: "",
    productId: "",
    activePrice: { price: "Custom", interval: "", priceId: "", status: "active" },
    status: "active",
    price: "Custom",
    btnText: "Get Started",
    offerings: ["Private Analyst Group", "Research Team Focused", "Comprehensive Data Access", "Custom Requirements"],
    prices: [],
  },
];
