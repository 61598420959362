import { Col, Flex, Row } from "antd";
import SectionHeader from "../../../common/SectionHeader";
import { missions } from "../../../../constants";
import MissionCard from "./MissionCard";

const MissionSection = () => {
  return (
    <Flex className="section-container pricing-section" vertical align="center" gap={60}>
      <SectionHeader title="Our Mission" description="" />
      <Row gutter={[24, 24]}>
        {missions.map((mission, index) => (
          <Col lg={12} xs={24} key={index}>
            <MissionCard key={index} title={mission.title} description={mission.description} />
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

export default MissionSection;
