import { ReactNode } from "react";
import "./styles.scss";
import { DashboardHeader, Sidebar } from "../../components/common";
import { Flex } from "antd";

const DashboardLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="dashboard-layout">
      <Flex gap={24} align="flex-start">
        <div className="dashboard-layout-sidebar">
          <Sidebar />
        </div>
        <Flex vertical className="dashboard-layout-content" gap={24}>
          <DashboardHeader />
          {children}
        </Flex>
      </Flex>
    </div>
  );
};

export default DashboardLayout;
