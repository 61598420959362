import { AnimatedDivider, ClientsSection, HeroSection, PricingSection, UserReviewsSection } from "../../components/common";

const Pricing = () => {
  return (
    <>
      <div className="landing-page-section">
        <HeroSection
          title="Uncover the Secrets of the Crypto Market"
          description="Dive into the depths of blockchain data with BlockSpy. Our cutting-edge platform offers unparalleled insights, giving you the edge to make informed decisions in the fast-paced world of cryptocurrency. Whether you're a beginner or a pro, unlock the power of analytics and stay one step ahead."
        />
        <AnimatedDivider />
        <ClientsSection />
        <PricingSection />
      </div>
      <UserReviewsSection />
    </>
  );
};
export default Pricing;
