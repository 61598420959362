import { Flex } from "antd";
import { FourWeeksInsiders, OneDayInsiders, SevenDaysInsiders, ThreeDaysInsiders } from "../../components/screens/Insiders";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RootState } from "../../slices";
import { SpyType } from "../../types";
import { connect } from "react-redux";
import { useToast } from "../../hooks";
import { useEffect } from "react";

interface InsidersProps {
  selectedSpy: SpyType;
}

const Insiders = ({ selectedSpy }: InsidersProps) => {
  let hasRun = false;
  const navigate = useNavigate();
  const { tableName } = useParams();
  const [searchParams, _] = useSearchParams();
  const { successToast, errorToast } = useToast();
  const success = searchParams.get("success");
  const canceled = searchParams.get("canceled");

  useEffect(() => {
    if (hasRun) return;
    hasRun = true;
    if (success === "true") {
      successToast("Success", "Payment Successfull");
      navigate("/dashboard");
    } else if (canceled === "true") {
      errorToast("Error", "Payment Failed");
      navigate("/dashboard");
    }
  }, [success, canceled]);

  return tableName && selectedSpy && Object.keys(selectedSpy).length ? (
    <Flex vertical gap={24}>
      <OneDayInsiders />
      <ThreeDaysInsiders />
      <SevenDaysInsiders />
      <FourWeeksInsiders />
    </Flex>
  ) : null;
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedSpy: state.spies.selectedSpy as SpyType,
  };
};

export default connect(mapStateToProps)(Insiders);
