import { ReactNode } from "react";

interface RouteWrapperProps {
  children: ReactNode;
}

const RouteWrapper = ({ children }: RouteWrapperProps) => {
  return <>{children}</>;
};

export default RouteWrapper;
