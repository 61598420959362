import joshWarrenPic from "../assets/images/josh-warren-pic.png";
import michaelTriplowskiPic from "../assets/images/michael-triplowski-pic.png";
import zackTanPic from "../assets/images/zack-tan-pic.png";
import nancyKillenbrandPic from "../assets/images/nancy-killenbrand-pic.png";
import mattGresiaPic from "../assets/images//matt-gresia-pic.png";
import stefanPollackPic from "../assets/images/stefan-pollack-pic.png";

export const userReviews = [
  {
    name: "Josh Warren",
    designation: "Blockchain Investor",
    img: joshWarrenPic,
    comment: `Blockspy is the only tool I use to get perfect my edge in the crypto market! I have tried a bunch of other competitors and Blockspy is for sure unmatched in the level of alpha that it delivers! Absolutely love the product and have recommended Blockspy to other traders as well!`,
  },
  {
    name: "Michael Triplowski",
    designation: "Blockchain Developer",
    img: michaelTriplowskiPic,
    comment: `All I can say is - THANK GOD FOR BLOCKSPY! Such a simple and easy user experience! I am now comfortably able to take actionable steps in my crypto trading with Blockspy! I can't imagine trading without it! `,
  },
  {
    name: "Zack Tan",
    designation: "Blockchain Developer",
    img: zackTanPic,
    comment: `At first, I was skeptical to try another blockchain data analytics platform as I didn't like my experience with competitors like Nansen. However, I was sold after my first test run with Blockspy because of how simple and easy it was to use. `,
  },
  {
    name: "Nancy Killenbrand",
    designation: "Blockchain Developer",
    img: nancyKillenbrandPic,
    comment: `Nothing better than Blockspy in my opinion for gaining inside knowledge of inflows/outflows within the crypto market. It stands alone as the most simple and user friendly way to read through very detailed and complex blockchain data. `,
  },
  {
    name: "Matt Gresia",
    designation: "Blockchain Developer",
    img: mattGresiaPic,
    comment: `My favorite part of Blockspy is the aggregated trading data over different periods of time. This helps me manage my long term and short term positions easily and effectively. I can't imagine trading again without Blockspy!`,
  },
  {
    name: "Stefan Pollack",
    designation: "Blockchain Developer",
    img: stefanPollackPic,
    comment: `Blockspy has amazing customer service. I had a problem logging into my account, I reached out to them and received a response
    within minutes! No better crypto team than Blockspy!`,
  },
];
