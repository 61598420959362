export const updates = [
  {
    title: "Demystifying Blockchain: A Beginner's Guide",
    description:
      "New to blockchain? This guide breaks down the basics, from what blockchain is to how it's revolutionizing industries. Start your blockchain journey here.",
    date: "04-01-2024",
  },
  {
    title: "Demystifying Blockchain: A Beginner's Guide",
    description:
      "New to blockchain? This guide breaks down the basics, from what blockchain is to how it's revolutionizing industries. Start your blockchain journey here.",
    date: "04-01-2024",
  },
  {
    title: "Demystifying Blockchain: A Beginner's Guide",
    description:
      "New to blockchain? This guide breaks down the basics, from what blockchain is to how it's revolutionizing industries. Start your blockchain journey here.",
    date: "04-01-2024",
  },
];
