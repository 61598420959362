import { createSlice } from "@reduxjs/toolkit";

const spySlice = createSlice({
  name: "selectedSpy",
  initialState: {
    selectedSpy: {},
  },
  reducers: {
    selectSpy: (state, { payload }) => {
      state.selectedSpy = payload;
    },
  },
});

const { actions, reducer } = spySlice;

export const { selectSpy } = actions;

export default reducer;
