import { Col, Flex, Row } from "antd";
import SectionHeader from "../SectionHeader";
import UpdateCard from "./UpdateCard";
import { updates } from "../../../constants/updates";
import GetStartedButton from "../GetStartedButton";

const UpdatesSection = () => {
  return (
    <Flex className="section-container pricing-section" vertical align="center" gap={60}>
      <SectionHeader title="Insights and Updates" description="Stay Informed with Our Latest Blockchain Articles" />
      <Row gutter={[24, 48]}>
        {updates.map((update, index) => {
          return (
            <Col lg={8} xs={24} key={index}>
              <Flex justify="center" align="flex-start" style={{ height: "100%" }}>
                <UpdateCard key={index} title={update.title} description={update.description} />
              </Flex>
            </Col>
          );
        })}
      </Row>
      <GetStartedButton />
    </Flex>
  );
};

export default UpdatesSection;
