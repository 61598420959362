// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-drawer-mask {
  background: linear-gradient(113deg, rgba(255, 255, 255, 0.05) 0.93%, rgba(255, 255, 255, 0) 97.36%);
  -webkit-backdrop-filter: blur(6.3499999046px);
          backdrop-filter: blur(6.3499999046px);
}

.dashboard-header-drawer .ant-drawer-header {
  display: none;
}
.dashboard-header-drawer .ant-drawer-content,
.dashboard-header-drawer .ant-drawer-body {
  background: #311d4d !important;
}
.dashboard-header-drawer .ant-drawer-body {
  padding: 0px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/DashboardHeader/DashboardHeaderDrawer/styles.scss"],"names":[],"mappings":"AAEA;EACE,mGAAA;EACA,6CAAA;UAAA,qCAAA;AADF;;AAKE;EACE,aAAA;AAFJ;AAKE;;EAEE,8BAAA;AAHJ;AAME;EACE,YAAA;EACA,WAAA;AAJJ","sourcesContent":["@import \"../../../../styles/variables\";\n\n.ant-drawer-mask {\n  background: linear-gradient(113deg, rgba(255, 255, 255, 0.05) 0.93%, rgba(255, 255, 255, 0) 97.36%);\n  backdrop-filter: blur(6.349999904632568px);\n}\n\n.dashboard-header-drawer {\n  .ant-drawer-header {\n    display: none;\n  }\n\n  .ant-drawer-content,\n  .ant-drawer-body {\n    background: $bg-sidebar !important;\n  }\n\n  .ant-drawer-body {\n    padding: 0px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
