import { Flex, TableProps } from "antd";
import { Insider } from "../types";
import { formatNumber } from "../utils";
import { CopyPopover } from "../components/screens/Insiders";
import { Link } from "react-router-dom";
import nftscanIcon from "../assets/images/nftscan-icon.svg";

export const nftTradedColumns: TableProps<Insider>["columns"] = [
  {
    title: "",
    dataIndex: "index",
    key: "index",
    sorter: (a, b) => a.index - b.index,
  },
  {
    title: "Blockchain",
    dataIndex: "blockchain",
    key: "blockchain",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
  },
  {
    title: "Market Cap",
    dataIndex: "Market Cap",
    key: "market_cap",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: (value: number) => value || 0,
  },
  {
    title: "Collection",
    dataIndex: "collection",
    key: "collection",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
  },
  {
    title: "Net Usd Flow",
    dataIndex: "net_usd_flow",
    key: "net_usd_flow",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => <p>{formatNumber(text)}</p>,
  },
  {
    title: "USD Buys",
    dataIndex: "usd_buys",
    key: "usd_buy",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => <p>{formatNumber(text)}</p>,
  },
  {
    title: "USD Sells",
    dataIndex: "usd_sells",
    key: "usd_sells",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => <p>{formatNumber(text)}</p>,
  },
  {
    title: "Buy Count",
    dataIndex: "buy_count",
    key: "buy_count",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
  },
  {
    title: "Sell Count",
    dataIndex: "sell_count",
    key: "sell_count",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
  },
  {
    title: "Spies transacted (%)",
    dataIndex: "% of Spies transacted",
    key: "% of Spies transacted",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => <p>{formatNumber(text ? parseFloat(text) : 0)}</p>,
  },
  {
    title: "NFT Contract Address",
    dataIndex: "nft_contract_address",
    key: "nft_contract_address",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => (
      <Flex gap={5} align="flex-end">
        {text?.slice(0, 25)}
        <CopyPopover text={text} />
      </Flex>
    ),
  },
  {
    title: "NFT Scan url",
    dataIndex: "nftscan_url",
    key: "nftscan_url",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => (
      <Link to={text} target="_blank" rel="noreferrer">
        <img className="nftscan-url-img" src={nftscanIcon} alt="nftscanIcon" />
      </Link>
    ),
  },
];

export const tokensTradedColumns: TableProps<Insider>["columns"] = [
  {
    title: "",
    dataIndex: "index",
    key: "index",
    sorter: (a, b) => a.index - b.index,
  },
  {
    title: "Blockchain",
    dataIndex: "blockchain",
    key: "blockchain",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
  },
  {
    title: "Token Symbol",
    dataIndex: "token_symbol",
    key: "token_symbol",
    sorter: (a, b) => a.token_symbol.localeCompare(b.token_symbol),
  },
  {
    title: "Market Cap",
    dataIndex: "Market Cap",
    key: "market_cap",
    sorter: (a, b) => a.market_cap.toString().localeCompare(b.market_cap.toString()),
  },
  {
    title: "Net Usd Flow",
    dataIndex: "net_flow_usd",
    key: "net_flow_usd",
    sorter: (a, b) => a.net_flow_usd.toString().localeCompare(b.net_flow_usd.toString()),
    render: text => <p>{formatNumber(text)}</p>,
  },
  {
    title: "USD Buys",
    dataIndex: "buy_amount_usd",
    key: "buy_amount_usd",
    sorter: (a, b) => a.buy_amount_usd.toString().localeCompare(b.buy_amount_usd.toString()),
    render: text => <p>{formatNumber(text)}</p>,
  },
  {
    title: "USD Sells",
    dataIndex: "sell_amount_usd",
    key: "sell_amount_usd",
    sorter: (a, b) => a.sell_amount_usd.toString().localeCompare(b.sell_amount_usd.toString()),
    render: text => <p>{formatNumber(text)}</p>,
  },
  {
    title: "Buyers",
    dataIndex: "buyers",
    key: "buyers",
    sorter: (a, b) => a.buyers.toString().localeCompare(b.buyers.toString()),
  },
  {
    title: "Sellers",
    dataIndex: "sellers",
    key: "sellers",
    sorter: (a, b) => a.sellers.toString().localeCompare(b.sellers.toString()),
  },
  {
    title: "Spies transacted (%)",
    dataIndex: "% of Spies transacted",
    key: "% of Spies transacted",
    sorter: (a, b) => a.blockchain.localeCompare(b.blockchain),
    render: text => <p>{formatNumber(text ? parseFloat(text) : 0)}</p>,
  },
  {
    title: "Token Address",
    dataIndex: "token_address",
    key: "token_address",
    sorter: (a, b) => a.token_address.localeCompare(b.token_address),
    render: text => (
      <Flex gap={5} align="flex-end">
        {text?.slice(0, 25)}
        <CopyPopover text={text} />
      </Flex>
    ),
  },
];
