import { Modal as AntdModal, ModalProps } from "antd";
import crossIcon from "../../../assets/images/cross-icon.png";
import "./styles.scss";

const Modal = (props: ModalProps) => {
  const { children, className, ...modalProps } = props;
  return (
    <AntdModal className={`custom-modal ${className}`} closeIcon={<img src={crossIcon} className="cursor-pointer" />} {...modalProps}>
      {children}
    </AntdModal>
  );
};

export default Modal;
