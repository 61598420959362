import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { Flex, Layout, Spin, Tree } from "antd";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/images/caret-down.png";
import chevronDown from "../../../assets/images/chevron-down.svg";
import crossIcon from "../../../assets/images/cross-icon.png";
import diamondIcon from "../../../assets/images/diamond-icon.svg";
import { sidebarTabs } from "../../../constants";
import { useMediaQuery, useToast } from "../../../hooks";
import { RootState } from "../../../slices";
import { toggleSidebar } from "../../../slices/sidebar.slice";
import { selectSpy } from "../../../slices/spy.slice";
import { OutlineButton } from "../../ui-elements";
import Logo from "../Logo";
import { SignOutButton, useAuth, useUser } from "@clerk/clerk-react";
import PlansModal from "../PlansModal";
import "./styles.scss";
import { useLazyGetSpyMenuQuery } from "../../../services/spyMenu";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Response, SpyType } from "../../../types";
import { Plan } from "../../../enums";

interface SidebarProps {
  toggleSidebar: () => void;
  selectSpy: (spy: object) => void;
}

const Sidebar = ({ toggleSidebar, selectSpy }: SidebarProps) => {
  const { errorToast } = useToast();
  const { user } = useUser();
  const { planType } = user?.publicMetadata || {};
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [open, setOpen] = useState(false);
  const { getToken, signOut } = useAuth();
  const [trigger, { data, isLoading, isError, error }] = useLazyGetSpyMenuQuery();
  const tablet = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const { Sider } = Layout;
  const [active, setActive] = useState("");
  const [autoExpand, setAutoExpand] = useState<boolean>(false);
  const location = useLocation();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      await signOut(() => navigate("/"));
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLogoutLoading(false);
    }
  };
  const expand = () => {
    setAutoExpand(!autoExpand);
  };

  const isDashboard = useMemo(() => {
    return location.pathname.includes("dashboard");
  }, [location]);

  const modifiedSidebarTabs = useMemo(() => {
    const newArray = [...sidebarTabs];
    const indexToInsert = 0;
    let childrenArrayToModify = newArray[indexToInsert].children || [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    childrenArrayToModify = data?.data.data.menu || [];
    newArray[indexToInsert].children = childrenArrayToModify;
    return newArray;
  }, [user, data]);

  const onExpand = useCallback((expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  }, []);

  const onSelect = useCallback(
    (selectedKeysValue: React.Key[], info: any) => {
      setExpandedKeys(selectedKeysValue);
      setSelectedKeys(selectedKeysValue);
      const { node } = info;
      if (node.title === "Buys" || node.title === "Sells") {
        tablet && toggleSidebar();
        selectSpy(node);
        navigate(`/dashboard/${node.tableName}`);
      }
    },
    [tablet],
  );

  const defaultNode = useMemo(() => {
    const nodes = data?.data.data.menu || [];
    if (!nodes || nodes.length === 0 || !isDashboard) return { key: "0" };
    const findDefaultNode = (nodes: SpyType[]) => {
      if (!nodes || nodes.length === 0) return { key: "0" };
      const firstNode = nodes[0];
      if (firstNode.title === "Buys") {
        return firstNode;
      }
      if (firstNode.children) {
        const found: Partial<SpyType> = findDefaultNode(firstNode.children);
        if (found) {
          return found;
        }
      }
      return { key: "0" };
    };
    return findDefaultNode(nodes);
  }, [data, isDashboard]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTabClick = useCallback(
    (tab: (typeof sidebarTabs)[0]) => {
      if (tablet && !isInsider(tab.name)) {
        toggleSidebar();
      }
      if (!isInsider(tab.name) && autoExpand) {
        expand();
      }
      if (isInsider(tab.name)) {
        navigate("/dashboard");
        expand();
      }
    },
    [tablet, autoExpand],
  );

  const isInsider = useCallback((tabName: string) => {
    return tabName.includes("Spy Tracking");
  }, []);

  const fetchSpyMenu = useCallback(async () => {
    const token = await getToken();
    if (token) {
      trigger({
        token,
      });
    }
  }, []);

  useEffect(() => {
    if (isDashboard) {
      setAutoExpand(true);
    }
  }, [isDashboard]);

  useEffect(() => {
    if (defaultNode) {
      setExpandedKeys([defaultNode.key || "0"]);
      setSelectedKeys([defaultNode.key || "0"]);
      if (defaultNode.title === "Buys" || defaultNode.title === "Sells") {
        selectSpy(defaultNode);
        navigate(`/dashboard/${defaultNode.tableName}`);
      }
    }
  }, [defaultNode]);

  useEffect(() => {
    if (isError && error) {
      const fetchBaseQueryError = error as FetchBaseQueryError;
      if (fetchBaseQueryError.data) {
        const { message } = fetchBaseQueryError.data as Response;
        errorToast("Error", message || "Something went wrong");
      } else {
        errorToast("Error", "Something went wrong");
      }
      if (fetchBaseQueryError.status === 401) signOut();
    }
  }, [isError, error]);

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  useLayoutEffect(() => {
    fetchSpyMenu();
  }, []);

  return (
    <>
      <Sider className="sidebar">
        <Flex justify="space-between" align="center">
          <Logo fontSize={22.5} imgSize={32} />
          <div className="sidebar-cross-icon" onClick={() => toggleSidebar()}>
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </Flex>
        <Flex className="sidebar-content" justify="space-between" vertical gap={50}>
          <Flex vertical gap={20}>
            {modifiedSidebarTabs.map((tab, index) => (
              <Flex
                className={`sidebar-menu-item ${
                  active.includes(tab.path) || (tab.name.includes("Spy Tracking") && autoExpand) ? "sidebar-menu-item-active" : ""
                }`}
                style={{ height: isInsider(tab.name) ? "auto" : "25px" }}
                key={index}
                vertical
                gap={12}
              >
                <Link key={index} to={tab.path} onClick={() => handleTabClick(tab)}>
                  <Flex key={index} gap={12} justify="space-between" align="center">
                    <Flex gap={12} align="center">
                      <img src={active === tab.path ? tab.activeIcon : tab.icon} alt={tab.name} width={16} height={16} />
                      <p className="sidebar-menu-item-name">{tab.name}</p>
                    </Flex>
                    <img
                      src={chevronDown}
                      className={autoExpand ? "sidebar-menu-item-chevron-up" : "sidebar-menu-item-chevron-down"}
                      alt="chevron-down"
                      style={{ display: isInsider(tab.name) ? "block" : "none" }}
                    />
                  </Flex>
                </Link>
                {tab.name.includes("Spy Tracking") && autoExpand ? (
                  isLoading ? (
                    <Spin />
                  ) : (
                    <Tree
                      treeData={tab.children}
                      onSelect={onSelect}
                      autoExpandParent={true}
                      onExpand={onExpand}
                      expandedKeys={expandedKeys}
                      selectedKeys={selectedKeys}
                    />
                  )
                ) : null}
              </Flex>
            ))}
          </Flex>
          {!planType || planType === Plan.SCOUT ? (
            <div className="upgrade-card-bg">
              <div className="upgrade-card-border" />
              <Flex className="upgrade-card" vertical gap={8} align="center">
                <img src={diamondIcon} alt="diamond" width={16} height={16} className="upgrade-card-icon" />
                <p className="upgrade-card-title">want to upgrade?</p>
                <p className="upgrade-card-description">Unlock more features and insights. </p>
                <OutlineButton className="upgrade-card-btn" onClick={() => setOpen(true)}>
                  Upgrade Now
                </OutlineButton>
              </Flex>
            </div>
          ) : (
            <></>
          )}
          <Flex vertical justify="flex-end" className="sidebar-menu">
            <Link to="/help" onClick={() => tablet && toggleSidebar()}>
              <Flex className="sidebar-menu-item" gap={12} align="center">
                <img src={arrowIcon} width={16} height={16} />
                <p className="sidebar-menu-item-name" style={{ fontWeight: 600 }}>
                  Help?
                </p>
              </Flex>
            </Link>
            <Flex className="sidebar-menu-item cursor-pointer" gap={12} align="center" onClick={handleLogout}>
              <img src={arrowIcon} width={16} height={16} />
              <p className="sidebar-menu-item-name" style={{ fontWeight: 600 }}>
                Logout
              </p>
              {logoutLoading && (
                <div className="centerLoading">
                  <Spin />
                </div>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Sider>
      <PlansModal open={open} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    sidebarOpen: state.sidebars.sidebarOpen,
    selectSpy: state.spies.selectedSpy,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      toggleSidebar,
      selectSpy,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
