import { Button as AntdButton, ButtonProps } from "antd";
import { ReactNode } from "react";
import "./styles.scss";

interface Props extends ButtonProps {
  children: ReactNode;
}

const Button = (props: Props) => {
  const { children, className, ...btnProps } = props;
  return (
    <AntdButton className={`custom-btn ${className}`} {...btnProps}>
      {children}
    </AntdButton>
  );
};

export default Button;
