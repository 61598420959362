import { Flex } from "antd";
import { OutlineButton } from "../../../ui-elements";
import { Link } from "react-router-dom";

const InsidersNoDataMessage = () => {
  return (
    <Flex className="insiders-card" style={{ maxWidth: "100%" }} vertical align="center" gap={15}>
      <p className="insiders-card-title">We apologize</p>
      <p className="insiders-card-description">
        but it seems there is currently no data available in this section. Our team is working diligently to provide you with the latest
        information. In the meantime, please feel free to explore other areas of our website.
      </p>
      <Link to="/">
        <OutlineButton className="insiders-card-btn">Return To Home Page</OutlineButton>
      </Link>
    </Flex>
  );
};

export default InsidersNoDataMessage;
