import { Flex } from "antd";
import "./styles.scss";
import { Insider } from "../../../types";
import { RootState } from "../../../slices";
import { selectRow } from "../../../slices/insider.slice";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";

interface InsiderGraphDataCardProps {
  insider: Insider;
  selectedRow: number;
  selectRow: (index: number) => void;
  index: number;
}

const InsiderGraphDataCard = ({ insider, selectedRow, selectRow, index }: InsiderGraphDataCardProps) => {
  return (
    <Flex
      id={`insider-graph-data-card-${index}`}
      className={`insider-graph-data-card  ${selectedRow === index ? "insider-graph-data-card-active" : ""} `}
      onClick={() => selectRow(index || 0)}
      vertical
      gap={4}
    >
      <Flex vertical>
        <p className="insider-graph-data-card-title">Blockchain</p>
        <p className="insider-graph-data-card-description">{insider.blockchain}</p>
      </Flex>
      <Flex justify="space-between">
        <Flex vertical>
          <p className="insider-graph-data-card-title">Token Symbol</p>
          <p className="insider-graph-data-card-description">{insider.token_symbol}</p>
        </Flex>
        <Flex vertical>
          <p className="insider-graph-data-card-title">Net USD Flows</p>
          <p className="insider-graph-data-card-description">{insider.net_flow_usd}</p>
        </Flex>
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedRow: state.insiders.selectedRow,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      selectRow,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InsiderGraphDataCard);
