import { Flex } from "antd";
import diamondIcon from "../../../../assets/images/diamond-icon.svg";
import { OutlineButton } from "../../../ui-elements";
import "./styles.scss";
import { PlansModal } from "../../../common";
import { useCallback, useState } from "react";

interface InsidersUpgradeOverlayProps {
  title: string;
}

const InsidersUpgradeOverlay = ({ title }: InsidersUpgradeOverlayProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Flex className="insiders-card" vertical align="center" style={{ maxWidth: "100%" }} gap={15}>
        <img src={diamondIcon} alt="diamond" width={16} height={16} className="card-icon" />
        <p className="insiders-card-title">Upgrade to Access {title} DCA by Insiders</p>
        <p className="insiders-card-description">
          Uncover deeper insights and enhanced analytics with our {title} DCA by Insiders. This advanced feature provides comprehensive data
          on dollar-cost averaging strategies, asset performance, and more.
        </p>
        <OutlineButton className="insiders-card-btn" onClick={() => setOpen(true)}>
          Upgrade Now
        </OutlineButton>
      </Flex>
      <PlansModal open={open} handleClose={handleClose} />
    </>
  );
};

export default InsidersUpgradeOverlay;
