export const missions = [
  {
    title: "Uncover Insider Insights",
    description: `Public blockchains have unlocked financial data in a way never before imagined by tradfi. We help you see the trades that tradfi never wanted you to see.`,
  },
  {
    title: "Empowerment Through Knowledge",
    description: `Our mission is to make blockchain data accessible and understandable to everyone. We believe that blockchain has the potential to transform industries, and we're here to ensure that potential is realized.`,
  },
  {
    title: "Transparency",
    description: `We're guided by a commitment to transparency. We believe that trust is paramount in the world of blockchain, and our users deserve to know where their data comes from and how it's processed.`,
  },
  {
    title: "Innovation",
    description: `Innovation is in our DNA. We constantly push the boundaries of what's possible in blockchain analytics. Our team of experts explores new frontiers, always striving for better solutions.`,
  },
];
