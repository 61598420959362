import { Input as AntdInput, InputProps } from "antd";
import "./styles.scss";

const Input = (props: InputProps) => {
  const { className } = props;
  return (
    <div className={`custom-input ${className}`}>
      <AntdInput {...props} />
    </div>
  );
};

export default Input;
