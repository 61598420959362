import { Button as AntdButton, ButtonProps } from "antd";
import { ReactNode } from "react";
import "./styles.scss";

interface Props extends ButtonProps {
  children: ReactNode;
}

const OutlineButton = (props: Props) => {
  const { children, className, ...btnProps } = props;
  return (
    <AntdButton className={`custom-outline-btn ${className}`} block {...btnProps}>
      {children}
    </AntdButton>
  );
};

export default OutlineButton;
