import { Flex } from "antd";
import DashboardContentSection from "../../components/common/DashboardContentSection";
import HowItWorksCard from "../../components/screens/Feedback/HowItWorksSection/HowItWorksCard";
import { ideas } from "../../constants";
import { FeedbackForm } from "../../components/screens/Feedback";
import "./styles.scss";

const Ideas = () => {
  return (
    <DashboardContentSection title="How It Works">
      <Flex className="ideas-card-container" justify="space-between" gap={24}>
        {ideas.map((mission, index) => (
          <div className="ideas-card" key={index}>
            <HowItWorksCard index={index} title={mission.title} description={mission.description} />
          </div>
        ))}
      </Flex>
      <FeedbackForm />
    </DashboardContentSection>
  );
};

export default Ideas;
