import { Flex } from "antd";
import logo from "../../../assets/images/logo.svg";
import "./styles.scss";
import { Link } from "react-router-dom";

interface LogoProps {
  fontSize?: number;
  imgSize?: number;
}

const Logo = ({ fontSize, imgSize }: LogoProps) => {
  return (
    <Link to="/">
      <Flex className="auth-logo-container" align="center" gap={4}>
        <img src={logo} className="auth-logo-img" width={imgSize} height={imgSize} />
        <h1 className="auth-logo-title" style={{ fontSize }}>
          Blockspy
        </h1>
      </Flex>
    </Link>
  );
};

Logo.defaultProps = {
  fontSize: 29.5,
  imgSize: 42,
};

export default Logo;
