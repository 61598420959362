import { Drawer } from "antd";
import Sidebar from "../../Sidebar";
import "./styles.scss";

interface DashboardHeaderDrawerProps {
  open: boolean;
  setOpen: () => void;
}

const DashboardHeaderDrawer = ({ open, setOpen }: DashboardHeaderDrawerProps) => {
  const handleDrawerClose = () => {
    setOpen();
  };

  return (
    <Drawer className="dashboard-header-drawer" open={open} onClose={handleDrawerClose}>
      <Sidebar />
    </Drawer>
  );
};

export default DashboardHeaderDrawer;
