import AboutUs from "./AboutUs";
import ForgetPassword from "./ForgetPassword";
import Home from "./Home";
import Login from "./Login";
import Pricing from "./Pricing";
import Signup from "./Signup";
import Feedback from "./Feedback";
import Features from "./Features";
import Dashboard from "./Dashboard";
import Insiders from "./Insiders";
import DashboardPricing from "./DashboardPricing";
import Ideas from "./Ideas";
import Settings from "./Settings";
import Help from "./Help";

export {
  AboutUs,
  ForgetPassword,
  Home,
  Login,
  Pricing,
  Signup,
  Feedback,
  Features,
  Dashboard,
  Insiders,
  DashboardPricing,
  Ideas,
  Settings,
  Help,
};
