import { Flex } from "antd";
import starImg from "../../../assets/images/star-img.png";
import { OutlineButton } from "../../ui-elements";
import GetStartedButton from "../GetStartedButton";

interface HeroSectionProps {
  title: string;
  description: string;
}

const HeroSection = ({ title, description }: HeroSectionProps) => {
  return (
    <Flex className="section-container hero-section" vertical justify="space-between" align="center" gap={40}>
      <p className="section-description">Real-Time Intelligence for Savvy Investors</p>
      <h1 className="hero-section-title hero-section-title-centered">
        {title} <img src={starImg} alt="start-img" className="hero-section-start-icon" />
      </h1>
      <p className="section-description">{description}</p>
      <Flex className="hero-section-btn-group" justify="center" gap={10}>
        <GetStartedButton text="Get Started For Free" />
        <OutlineButton>Browse Our Plans</OutlineButton>
      </Flex>
    </Flex>
  );
};

export default HeroSection;
