import { AnimatedDivider, ClientsSection, HeroSection, UserReviewsSection } from "../../components/common";
import { FeedbackForm } from "../../components/screens/Feedback";
import HowItWorksSection from "../../components/screens/Feedback/HowItWorksSection";

const Feedback = () => {
  return (
    <>
      <div className="landing-page-section">
        <HeroSection
          title="Share Your Thoughts, Shape Our Future"
          description="Your feedback matters. Help us improve Blockspy by sharing your ideas and suggestions."
        />
        <AnimatedDivider />
        <ClientsSection />
        <HowItWorksSection />
        <FeedbackForm showTitle={true} />
      </div>
      <UserReviewsSection />
    </>
  );
};
export default Feedback;
