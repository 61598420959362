import { Flex, PaginationProps, Spin } from "antd";
import { DashboardContentSection } from "../../../common";
import InsidersTable from "../InsidersTable";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLazyGetOneDayInsidersQuery } from "../../../../services/insider";
import InsidersNoDataMessage from "../InsidersNoDataMessage";
import { Pagination } from "../../../ui-elements";
import { useParams } from "react-router-dom";
import { useAuth, useUser } from "@clerk/clerk-react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useToast } from "../../../../hooks";
import { Response, SpyType, FiltersType } from "../../../../types";
import { RootState } from "../../../../slices";
import { connect } from "react-redux";
import { nftTradedColumns, tokensTradedColumns } from "../../../../constants";
import InsidersUpgradeOverlay from "../InsidersUpgradeOverlay";
import { filtersInitialState } from "../../../../constants/filter";

interface OneDayInsidersProps {
  selectedSpy: SpyType;
}

const OneDayInsiders = ({ selectedSpy }: OneDayInsidersProps) => {
  const [filters, setFilters] = useState(filtersInitialState);
  const { user } = useUser();
  const { errorToast } = useToast();
  const { getToken, signOut } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const { tableName } = useParams();
  const [trigger, result] = useLazyGetOneDayInsidersQuery();
  const { data, isLoading, isError, error } = result;
  const [loading, setIsLoading] = useState(false); // Add isLoading state

  const totalCount = useMemo(() => {
    if (data?.data?.totalCount) {
      return data?.data?.totalCount;
    } else return 0;
  }, [data]);

  const onPaginationChange: PaginationProps["onChange"] = page => {
    setCurrentPage(page);
  };
  const setLoading = (loading: boolean) => {
    setIsLoading(loading);
  };
  const fetchToken = useCallback(async () => {
    const token = await getToken();
    if (token && checkPermission("1d")) {
      setLoading(true);
      trigger({
        filters: JSON.stringify(filters),
        page: currentPage,
        tableName,
        token,
      }).then(() => setLoading(false));
    }
  }, [tableName, currentPage, selectedSpy, user, filters]);

  const checkPermission = useCallback(
    (day: string) => {
      if (selectedSpy && Object.keys(selectedSpy).length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return selectedSpy?.permissions[day]?.includes(user?.publicMetadata?.planType as string);
      } else return false;
    },
    [selectedSpy, user],
  );

  const showIframe = useMemo(() => {
    if (selectedSpy && Object.keys(selectedSpy).length) return selectedSpy?.iframe;
    else if (tableName?.includes("NFT")) return false;
    else return true;
  }, [selectedSpy]);

  const handleFiltersChange = (newFilters: FiltersType) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    if (isError && error) {
      const fetchBaseQueryError = error as FetchBaseQueryError;
      if (fetchBaseQueryError.data) {
        const { message } = fetchBaseQueryError.data as Response;
        errorToast("Error", message || "Something went wrong");
      } else {
        errorToast("Error", "Something went wrong");
      }
      if (fetchBaseQueryError.status === 401) signOut();
    }
  }, [isError, error]);

  useLayoutEffect(() => {
    fetchToken();
  }, [tableName, currentPage, selectedSpy, user, filters]);
  return (
    <DashboardContentSection title="Last One Day">
      {checkPermission("1d") ? (
        isLoading || loading ? (
          <Spin />
        ) : Array.isArray(data?.data.data) && data?.data.data.length ? (
          <>
            <InsidersTable
              dataSource={data?.data.data || []}
              dataCount={totalCount}
              currentPage={currentPage}
              columns={showIframe ? tokensTradedColumns : nftTradedColumns}
              setCurrentPage={setCurrentPage}
              loading={isLoading}
              onFiltersChange={handleFiltersChange}
            />
            <Flex justify="center">
              <Pagination
                total={totalCount}
                current={currentPage}
                showSizeChanger={false}
                showPrevNextJumpers={true}
                onChange={onPaginationChange}
              />
            </Flex>
          </>
        ) : (
          <InsidersNoDataMessage />
        )
      ) : (
        <InsidersUpgradeOverlay title="1-d" />
      )}
    </DashboardContentSection>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedSpy: state.spies.selectedSpy as SpyType,
  };
};

export default connect(mapStateToProps)(OneDayInsiders);
