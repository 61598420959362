import { Flex } from "antd";
import { Input, OutlineButton } from "../../../ui-elements";
import "./styles.scss";
import { useUser } from "@clerk/clerk-react";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { EmailAddressResource } from "@clerk/types";
import ErrorText from "../../../common/ErrorText";
import { otpFormValidationSchema, updateEmailFormValidationSchema } from "../../../../validation-schemas";
import { useToast } from "../../../../hooks";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "../../../../slices/loading.slice";
import { RootState } from "../../../../slices";

interface EmailSettingsProps {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const EmailSettings = ({ loading, startLoading, stopLoading }: EmailSettingsProps) => {
  const { successToast, errorToast } = useToast();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [clerkEmailAddress, setClerkEmailAddress] = useState<EmailAddressResource | undefined>(undefined);
  const { isLoaded, user } = useUser();

  const initialValues = { email: "" };

  const otpFormInitialValues = { otp: "" };

  const updateUser = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      const { resetForm } = actions;
      const { email } = values;
      const emailAddress = await user?.createEmailAddress({ email });
      await emailAddress?.prepareVerification({ strategy: "email_code" });
      successToast("Successful", "We have sent you an email with a verification code.");
      setShowOtpForm(true);
      setClerkEmailAddress(emailAddress);
      resetForm();
      stopLoading();
    } catch (error: any) {
      stopLoading();
      errorToast("Failed", error.errors[0].longMessage || error.message);
      console.error("error", error.errors || error.message);
    }
  };

  const handleOtpVerification = async (values: typeof otpFormInitialValues, actions: FormikHelpers<typeof otpFormInitialValues>) => {
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      const { resetForm } = actions;
      const { otp } = values;
      await clerkEmailAddress?.attemptVerification({ code: otp });
      await user?.primaryEmailAddress?.destroy();
      successToast("Successful", "Email updated successfully.");
      setShowOtpForm(false);
      resetForm();
      stopLoading();
    } catch (error: any) {
      stopLoading();
      console.error("error", error.errors || error.message);
      errorToast("Failed", error.errors[0].longMessage || error.message);
    }
  };

  return (
    <>
      {!showOtpForm && (
        <Formik initialValues={initialValues} validationSchema={updateEmailFormValidationSchema} onSubmit={updateUser}>
          {({ handleSubmit, handleChange, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Flex className="user-settings-container" vertical gap={24}>
                <Flex className="user-settings-inputs-container" gap={24}>
                  <Flex className="user-settings-container-column" vertical gap={12}>
                    <Flex className="user-settings-container-column" align="center" gap={60}>
                      <p className="user-settings-container-input-label">Change Email</p>
                      <Input
                        name="email"
                        status={errors.email && touched.email ? "error" : ""}
                        className="user-settings-container-input"
                        placeholder="Change Email"
                        onChange={handleChange}
                      />
                    </Flex>
                    {errors.email && touched.email && <ErrorText text={errors.email} />}
                  </Flex>
                  <Flex className="user-settings-container-column" justify="space-between" align="center">
                    <p className="user-settings-container-input-label">Current Email</p>
                    <p className="user-settings-container-current-email">{user?.primaryEmailAddress?.emailAddress}</p>
                  </Flex>
                </Flex>
                <OutlineButton className="save-btn" htmlType="submit" loading={loading}>
                  Save Changes
                </OutlineButton>
              </Flex>
            </form>
          )}
        </Formik>
      )}
      {showOtpForm && (
        <Formik initialValues={otpFormInitialValues} validationSchema={otpFormValidationSchema} onSubmit={handleOtpVerification}>
          {({ handleSubmit, handleChange, handleBlur, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Flex className="user-settings-container" vertical gap={24} justify="flex-start" align="flex-start">
                <Flex className="user-settings-container-column" align="center" gap={60}>
                  <p className="user-settings-container-input-label">Enter Otp</p>
                  <Input
                    name="otp"
                    autoFocus
                    status={errors.otp && touched.otp ? "error" : ""}
                    placeholder="Enter your otp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Flex>
                {errors.otp && touched.otp && <ErrorText text={errors.otp} />}
                <OutlineButton className="save-btn" htmlType="submit" loading={loading}>
                  Verify
                </OutlineButton>
              </Flex>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      startLoading,
      stopLoading,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettings);
