export const features = [
  {
    title: "Real-Time Analytics",
    description:
      "Experience the pulse of the blockchain. Our platform provides live data streams, offering you frequent updates on market movements, transaction flows, and asset performance. Make decisions powered by the most current data available.",
  },
  {
    title: "User-Friendly Interface",
    description:
      "Navigate with ease. Blockspy's user interface is designed for intuitive exploration of complex data. Whether you’re a blockchain novice or a data scientist, our platform is built to enhance your understanding without a steep learning curve.",
  },
  {
    title: "Comprehensive Data Aggregation",
    description:
      "Aggregate with intelligence. Blockspy brings together vast sources of data, providing a consolidated view of the blockchain. From transaction histories to wallet analysis, our aggregation methods ensure you have a complete picture.",
  },
  {
    title: "Advanced Security Measures",
    description:
      "Trust our secure analytics. We prioritize your data security with industry-leading encryption and continuous monitoring. Analyze projects and our data knowing that your personal information is protected.",
  },
  {
    title: "Expert Support",
    description:
      "We are blockchain experts ready to help you navigate any challenges. Accessible support ensures you maximize the value of our platform!",
  },
];
