// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-page .divider {
  border-block-start: 1px solid #4a5568;
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/styles.scss"],"names":[],"mappings":"AAGE;EACE,qCAAA;AAFJ","sourcesContent":["@import \"../../styles/variables\";\n\n.settings-page {\n  .divider {\n    border-block-start: 1px solid $gray-700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
