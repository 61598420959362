import { useLocation } from "react-router-dom";
import { AnimatedDivider, ClientsSection, WhoWeAreSection, PricingSection, UserReviewsSection } from "../../components/common";
import { FeaturesSection, FactsSection, HomeHeroSection } from "../../components/screens/Home";
import ReactGA from "react-ga";
import { useEffect } from "react";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <div className="landing-page-section">
        <HomeHeroSection />
        <AnimatedDivider />
        <ClientsSection />
        <FactsSection />
      </div>
      <FeaturesSection />
      <div className="landing-page-section">
        <WhoWeAreSection description="Blockspy is the industry leader in tracking profitable trendspotters on-chain, providing you with actionable insights to help your research. Our mission is to empower individuals, businesses, and organizations with the data and knowledge they need to make informed decisions in the ever-evolving blockchain landscape." />
        <PricingSection />
      </div>
      <UserReviewsSection />
    </>
  );
};
export default Home;
