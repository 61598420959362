import * as yup from "yup";

export const emailValidationSchema = yup
  .string()
  .email("Please provide your valid email address")
  .required("Please provide your email address.");

export const passwordValidationSchema = yup
  .string()
  .min(8, "Password must be at least 8 characters long.")
  .required("Please provide your password.");

export const confirmPasswordValidationSchema = yup.string().oneOf([yup.ref("password"), ""], "Passwords must match");

export const nameValidationSchema = yup.string();
