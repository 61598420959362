import insiderIcon from "../assets/images/insider-icon.png";
import priceIcon from "../assets/images/price-icon.png";
import ideasIcon from "../assets/images/idea-icon.png";
import ideasIconActive from "../assets/images/idea-icon-active.png";

export const sidebarTabs = [
  {
    name: "Spy Tracking",
    headerDescription: "Deep Dive into Blockchain Data",
    headerName: "Insider Analytics",
    path: "#",
    icon: insiderIcon,
    activeIcon: insiderIcon,
    title: "",
    key: "0-0",
    children: [],
  },
  {
    name: "Pricing",
    headerName: "Pricing",
    path: "/pricing-plans",
    icon: priceIcon,
    activeIcon: priceIcon,
  },
  {
    name: "Ideas?",
    headerDescription: "Share Your Ideas, Collaborate, and Shape the Future of Blockchain Analytics",
    headerName: "Innovation Starts Here",
    path: "/ideas",
    icon: ideasIcon,
    activeIcon: ideasIconActive,
  },
];
