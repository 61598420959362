import { Flex } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { scrollToDiv } from "../../../utils";
import DisclaimerModal from "../DisclaimerModal";
import "./styles.scss";

interface NavigationLinksProps {
  links: {
    name: string;
    path: string;
    id?: string;
    type: string;
    showModal?: boolean;
    icon?: string;
  }[];
  vertical?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  gap?: number;
}

const NavigationLinks = ({ links, vertical, gap, setOpen }: NavigationLinksProps) => {
  const [active, setActive] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => setActive(location.pathname), [location]);

  const handleLinkClick = useCallback((link: (typeof links)[number]) => {
    switch (link.type) {
      case "link":
        navigate(link.path);
        break;
      case "scroll":
        navigate(link.path);
        setTimeout(() => {
          scrollToDiv(link.id as string);
        }, 250);
        break;
      case "redirect":
        window.open(link.path, "_blank");
        break;
      case "modal":
        setOpenModal(true);
        break;

      default:
        navigate(link.path);
        break;
    }
    setOpen && setOpen(false);
  }, []);

  return (
    <>
      <Flex className="nav-links-container" vertical={vertical} gap={gap}>
        {links.map((link, index) => (
          <div key={index} className={`nav-link ${active === link.path ? "nav-link-active" : ""}`} onClick={() => handleLinkClick(link)}>
            <Flex gap={12} align="center">
              {link?.icon && <img src={link?.icon} />}
              <p>{link.name}</p>
            </Flex>
          </div>
        ))}
      </Flex>
      <DisclaimerModal handleClose={handleClose} open={openModal} />
    </>
  );
};

NavigationLinks.defaultProps = {
  vertical: true,
  gap: 32,
};

export default NavigationLinks;
