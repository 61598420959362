import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Plan } from "../types";
import { annualPricingPlans } from "../constants";
import { PlanInterval } from "../enums";

interface ReturnType {
  data: {
    data: Plan[];
  };
}

interface PayloadType {
  interval?: string;
}

export const planApi = createApi({
  reducerPath: "planApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
  }),
  endpoints: builder => ({
    getPlans: builder.query<ReturnType, PayloadType>({
      query: ({ interval }) => {
        return {
          url: "plan",
          params: {
            interval,
          },
        };
      },
      transformResponse: (response: ReturnType, _, { interval }) => {
        const { data } = response.data;

        if (interval === PlanInterval.YEARLY || !interval) {
          data.push(annualPricingPlans[0]);
        }

        const transformedData = data.map(plan => {
          const activePrice = plan.activePrice
            ? {
                ...plan.activePrice,
                price: typeof plan.activePrice.price === "number" ? plan.activePrice.price / 100 : plan.activePrice.price, //cents to dollar conversion
              }
            : null;
          return {
            ...plan,
            activePrice,
          };
        });

        return {
          data: {
            data: transformedData,
          },
        };
      },
    }),
  }),
});

export const { useGetPlansQuery } = planApi;
