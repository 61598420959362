// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-layout {
  padding: 32px;
}
@media screen and (max-width: 500px) {
  .auth-layout {
    padding: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/AuthLayout/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAEE;EAFF;IAGI,aAAA;EACF;AACF","sourcesContent":["@import \"../../styles/variables\";\n\n.auth-layout {\n  padding: 32px;\n  @media screen and (max-width: $sm-breakpoint) {\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
