import { Routes, Route, BrowserRouter } from "react-router-dom";
import { authRoutes, routes, dashboardRoutes } from "../constants";
import RouteWrapper from "../wrappers/RouteWrapper";
import AuthLayout from "../layouts/AuthLayout";
import { ReactNode, useCallback, useEffect, useState } from "react";
import UserLayout from "../layouts/UserLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DisclaimerModal } from "../components/common";
import Cookies from "js-cookie";

const Router = () => {
  const [open, setOpen] = useState(false);

  const NavigateToDashboard = () => {
    window.location.href = "/dashboard";
    return <></>;
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const getAuthLayout = (Component: ReactNode) => {
    return (
      <>
        <SignedIn>
          <NavigateToDashboard />
        </SignedIn>
        <SignedOut>
          <AuthLayout>{Component}</AuthLayout>
        </SignedOut>
      </>
    );
  };

  const getUserLayout = (Component: ReactNode) => {
    return <UserLayout>{Component}</UserLayout>;
  };

  const getDashboardLayout = (Component: ReactNode) => {
    return (
      <>
        <SignedIn>
          <DashboardLayout>{Component}</DashboardLayout>
        </SignedIn>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
      </>
    );
  };

  useEffect(() => {
    const agreed = Cookies.get("ToS");
    if (!agreed) setOpen(true);
  }, []);

  return (
    <>
      {" "}
      <BrowserRouter>
        <RouteWrapper>
          <ToastContainer hideProgressBar={true} className="custom-toast" pauseOnFocusLoss={false} />
          <Routes>
            {routes.map((route, index) => {
              return <Route key={index} path={route.path} element={getUserLayout(<route.component />)} />;
            })}
            {authRoutes.map((route, index) => {
              return <Route key={index} path={route.path} element={getAuthLayout(<route.component />)} />;
            })}
            {dashboardRoutes.map((route, index) => {
              return <Route key={index} path={route.path} element={getDashboardLayout(<route.component />)} />;
            })}
          </Routes>
        </RouteWrapper>
      </BrowserRouter>
      <DisclaimerModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Router;
