import { Flex } from "antd";
import { facts } from "../../../../constants";
import { motion } from "framer-motion";
import "./styles.scss";

const FactsSection = () => {
  return (
    <Flex className="section-container facts-section" vertical align="center" gap={60}>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} viewport={{ once: true }}>
        <Flex className="facts" gap={134}>
          {facts.map((fact, index) => (
            <Flex key={index} vertical gap={60}>
              <Flex vertical gap={20}>
                <h1 className="section-title facts-section-title">{fact.title}</h1>
                <Flex className="facts-section-icon" justify="center" align="center">
                  <img src={fact.icon} />
                </Flex>
              </Flex>
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                <Flex key={index} vertical>
                  <p className="facts-title">{fact.fact}</p>
                  <p className="facts-description">{fact.description}</p>
                </Flex>
              </motion.div>
            </Flex>
          ))}
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default FactsSection;
