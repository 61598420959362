import { Flex, Spin } from "antd";
import Logo from "../../../common/Logo";
import { Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import Input from "../../../ui-elements/Input";
import emailIcon from "../../../../assets/images/mail-icon.svg";
import passwordIcon from "../../../../assets/images/key-icon.svg";
import PasswordInput from "../../../ui-elements/PasswordInput";
import Button from "../../../ui-elements/Button";
import Checkbox from "../../../ui-elements/Checkbox";
import { signupFormValidationSchema } from "../../../../validation-schemas";
import ErrorText from "../../../common/ErrorText";
import { useSignUp } from "@clerk/clerk-react";
import { useCallback, useState } from "react";
import OtpForm from "../OtpForm";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../../../../slices/loading.slice";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../../../slices";
import { useToast } from "../../../../hooks";
import { DisclaimerModal } from "../../../common";
import { OAuthStrategy } from "@clerk/types";
interface SignupFormProps {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

const SignupForm = ({ loading, startLoading, stopLoading }: SignupFormProps) => {
  const [open, setOpen] = useState(false);
  const { successToast, errorToast } = useToast();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const { isLoaded, signUp } = useSignUp();
  const [waitingForGoogle, setWaitingForGoogle] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const initialValues = { email: "", password: "", confirmPassword: "", agreeToTerms: false };

  const handleSignup = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    const { resetForm } = actions;
    if (!isLoaded) {
      return;
    }
    try {
      startLoading();
      await signUp.create({ ...values, emailAddress: values.email, password: values.password });

      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
      successToast("Successful", "We have sent you an email with a verification code.");
      resetForm();
      setShowOtpForm(true);
      stopLoading();
    } catch (err: any) {
      errorToast("Signup Failed", err.errors[0].longMessage || err.message);
      stopLoading();
      console.log(values, "checking values");
      console.error("error", err.errors || err.message);
    }
  };

  const signUpWith = async (strategy: OAuthStrategy) => {
    console.log("ddd");

    setWaitingForGoogle(true);
    try {
      return signUp?.authenticateWithRedirect({
        strategy,
        redirectUrl: "/dashboard",
        redirectUrlComplete: "/dashboard",
      });
    } catch (error) {
      console.error("Sign-in error:", error);
    } finally {
      setTimeout(() => {
        setWaitingForGoogle(false);
      }, 2000);
    }
  };
  return (
    <>
      {!showOtpForm && (
        <Formik initialValues={initialValues} validationSchema={signupFormValidationSchema} onSubmit={handleSignup}>
          {({ handleSubmit, handleChange, errors, touched }) => (
            <form className="auth-form" onSubmit={handleSubmit}>
              <Flex vertical gap={100}>
                <Logo />
                <Flex vertical gap={32}>
                  <Flex vertical gap={4}>
                    <h1 color="white" className="auth-form-title">
                      Join Blockspy
                    </h1>
                    <p className="auth-form-subtitle">Create Your Account for Blockchain Insights</p>
                  </Flex>
                  <Flex vertical gap={16}>
                    <Input
                      name="email"
                      status={errors.email && touched.email ? "error" : ""}
                      placeholder="Enter your email"
                      prefix={<img src={emailIcon} />}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email && <ErrorText text={errors.email} />}
                    <PasswordInput
                      name="password"
                      status={errors.password && touched.password ? "error" : ""}
                      placeholder="Enter your password"
                      prefix={<img src={passwordIcon} />}
                      onChange={handleChange}
                    />
                    {errors.password && touched.password && <ErrorText text={errors.password} />}
                    <PasswordInput
                      name="confirmPassword"
                      status={errors.confirmPassword && touched.confirmPassword ? "error" : ""}
                      placeholder="Confirm your password"
                      prefix={<img src={passwordIcon} />}
                      onChange={handleChange}
                    />
                    {errors.confirmPassword && touched.confirmPassword && <ErrorText text={errors.confirmPassword} />}
                    <Flex gap={4}>
                      <Checkbox name="agreeToTerms" onChange={handleChange} />
                      <p className="auth-form-tos-text">
                        I agree to the <a onClick={() => setOpen(true)}>Terms and Conditions</a>
                      </p>
                    </Flex>

                    {errors.agreeToTerms && touched.agreeToTerms && <ErrorText text={errors.agreeToTerms} />}
                  </Flex>
                  <Button htmlType="submit">{loading ? <Spin /> : <p> Create Account</p>}</Button>
                  <Button className="google-btn" onClick={() => signUpWith("oauth_google")}>
                    {waitingForGoogle ? <Spin /> : <p> Signup with Google</p>}
                  </Button>
                  <Flex vertical align="center" gap={4}>
                    <p className="auth-form-footer-text">Already have an account?</p>
                    <Link to="/login" replace className="link">
                      Login
                    </Link>
                  </Flex>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      )}
      <DisclaimerModal open={open} handleClose={handleClose} />
      {showOtpForm && <OtpForm setShowOtpForm={setShowOtpForm} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      startLoading,
      stopLoading,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
