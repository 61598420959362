import clockIcon from "../assets/images/clock-icon.svg";
import muscleIcon from "../assets/images/muscle-icon.svg";
import targetIcon from "../assets/images/target-icon.svg";

export const facts = [
  {
    title: "Be Early.",
    icon: clockIcon,
    fact: "500M+",
    description: "Tracked Transactions",
  },
  {
    title: "Be Confident.",
    icon: muscleIcon,
    fact: "100%",
    description: "Insider Wallets",
  },
  {
    title: "Be Decisive.",
    icon: targetIcon,
    fact: "1TB+",
    description: "Data Analyzed Weekly",
  },
];
