import * as yup from "yup";
import { passwordValidationSchema, confirmPasswordValidationSchema } from "./commonSchemas";
import { forgetPasswordFormValidationSchema } from "./forgetPasswordSchema";

export const signupFormValidationSchema = forgetPasswordFormValidationSchema.concat(
  yup.object({
    password: passwordValidationSchema,
    confirmPassword: confirmPasswordValidationSchema,
  }),
);
