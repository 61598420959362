import { Flex } from "antd";
import "./styles.scss";
import { ReactNode } from "react";

interface DashboardContentSectionProps {
  title: string;
  icon?: string;
  children?: ReactNode;
}

const DashboardContentSection = ({ title, icon, children }: DashboardContentSectionProps) => {
  return (
    <Flex className="dashboard-content-section" vertical gap={16}>
      <Flex justify="space-between" align="center">
        <p className="dashboard-content-section-title">{title}</p>
        {icon && (
          <Flex className="dashboard-content-section-icon" justify="center" align="center">
            <img src={icon} alt="icon" />
          </Flex>
        )}
      </Flex>
      {children}
    </Flex>
  );
};

export default DashboardContentSection;
