import { Flex } from "antd";
import starImg from "../../../assets/images/star-img.png";
import { motion } from "framer-motion";
import GetStartedButton from "../GetStartedButton";

interface HeroSectionDividedProps {
  title: string;
  description: string;
  img: string;
}

const HeroSectionDivided = ({ title, description, img }: HeroSectionDividedProps) => {
  const spring = {
    type: "spring",
    damping: 8,
    stiffness: 28,
  };
  return (
    <Flex className="section-container hero-section" justify="space-between" align="center" gap={40}>
      <motion.div
        className="section-left-column hero-section-text"
        initial={{ opacity: 0, scale: 0, transformOrigin: "left center" }}
        animate={{ opacity: 1, scale: 1 }}
        transition={spring}
      >
        <h1 className="hero-section-title">
          {title} <img src={starImg} alt="start-img" className="hero-section-start-icon" />
        </h1>
        <p className="section-description hero-section-description">{description}</p>
        <GetStartedButton className="section-get-started-btn" />
      </motion.div>
      <Flex className="section-right-column" vertical justify="center">
        <motion.div
          layout
          initial={{ opacity: 0.5, x: 100, y: -100 }}
          animate={{ opacity: 1, scale: 1, x: 0, y: 0, rotateY: 360 }}
          transition={spring}
        >
          <img src={img} alt="home-bg-img" className="hero-section-bg-img" />
        </motion.div>
      </Flex>
    </Flex>
  );
};

export default HeroSectionDivided;
