import { Flex, MenuProps } from "antd";
import avatar from "../../../assets/images/profile-pic.jpeg";
import settingsIcon from "../../../assets/images/settings-icon.png";
import logoutIcon from "../../../assets/images/logout-icon.png";
import chevronLeft from "../../../assets/images/chevron-left.png";
import menuIcon from "../../../assets/images/menu-icon.png";
import { Dropdown } from "../../ui-elements";
import { useMemo, useState } from "react";
import { sidebarTabs } from "../../../constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../Logo";
import DashboardHeaderDrawer from "./DashboardHeaderDrawer";
import { toggleSidebar } from "../../../slices/sidebar.slice";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../../slices";
import { SignOutButton, useUser } from "@clerk/clerk-react";
import { useMediaQuery } from "../../../hooks";
import "./styles.scss";
import { useClerk } from "@clerk/clerk-react";
import { Spin } from "antd";
interface DashboardHeaderProps {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
}

const DashboardHeader = ({ sidebarOpen, toggleSidebar }: DashboardHeaderProps) => {
  const tablet = useMediaQuery("(max-width: 768px)");
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { insiderName = "" } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useClerk();

  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      await signOut(() => navigate("/"));
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLogoutLoading(false);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Flex align="center" justify="space-between" onClick={() => navigate("/settings")}>
          <p className="menu-title">Profile Setting</p>
          <img src={settingsIcon} />
        </Flex>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Flex className="logoutStyle" align="center" onClick={handleLogout}>
          <p className="menu-title logout-text">Logout</p>
          <img src={logoutIcon} />
        </Flex>
      ),
    },
  ];

  const tab = useMemo(() => {
    const sidebar = sidebarTabs.find(tab => location.pathname.includes(tab.path));
    if (location.pathname === "/settings") {
      return { headerName: "Manage Your Account", headerDescription: "" };
    } else if (location.pathname === "/help") {
      return { headerName: "Help & Support", headerDescription: "" };
    } else if (location.pathname.includes("/dashboard")) {
      return { headerName: insiderName + " " + "Insider Analytics", headerDescription: sidebar?.headerDescription };
    } else {
      return sidebar;
    }
  }, [location]);

  return (
    <>
      <Flex vertical className="dashboard-header" gap={24}>
        <Flex justify="space-between">
          {!tablet && (
            <Flex gap={12} align="center">
              {location.pathname === "/settings" && (
                <Flex className="dashboard-header-back-btn" justify="center" align="center" onClick={() => navigate(-1)}>
                  <img src={chevronLeft} alt="chevron left" />
                </Flex>
              )}
              <Flex className="dashboard-header-heading" vertical>
                <p className="dashboard-header-title">{tab?.headerName}</p>
                <p className="dashboard-header-description">{tab?.headerDescription}</p>
              </Flex>
            </Flex>
          )}
          <div className="dashboard-header-logo">
            <Logo />
          </div>
          <Flex align="center" gap={12}>
            <Dropdown className="cursor-pointer" menu={{ items }} trigger={["click"]}>
              <Flex align="center" gap={12}>
                <img src={avatar} alt="avatar" className="dashboard-header-avatar" />
                <p className="dashboard-header-username">{user?.fullName}</p>
              </Flex>
            </Dropdown>
            <div className="dashboard-header-menu-icon">
              <img src={menuIcon} onClick={() => toggleSidebar()} />
            </div>
          </Flex>
        </Flex>
        {tablet && (
          <Flex gap={12} align="center">
            {location.pathname === "/settings" && (
              <Flex className="dashboard-header-back-btn" justify="center" align="center" onClick={() => navigate(-1)}>
                <img src={chevronLeft} alt="chevron left" />
              </Flex>
            )}
            <Flex className="dashboard-header-heading" vertical>
              <p className="dashboard-header-title">{tab?.headerName}</p>
              <p className="dashboard-header-description">{tab?.headerDescription}</p>
            </Flex>
          </Flex>
        )}
      </Flex>
      <DashboardHeaderDrawer open={sidebarOpen} setOpen={toggleSidebar} />
      {logoutLoading && (
        <div className="logoutLoading">
          <Spin />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    sidebarOpen: state.sidebars.sidebarOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      toggleSidebar,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
