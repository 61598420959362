import { Flex } from "antd";
import { toast } from "react-toastify";

const useToast = () => {
  const Msg = ({ message, description }: { message: string; description: string }) => (
    <Flex vertical gap={8}>
      <p className="custom-toast-msg">{message}</p>
      <p className="custom-toast-description">{description}</p>
    </Flex>
  );

  const successToast = (message: string, description: string) => {
    toast.success(<Msg message={message} description={description} />);
  };

  const errorToast = (message: string, description: string) => {
    toast.error(<Msg message={message} description={description} />);
  };

  return { successToast, errorToast };
};

export default useToast;
