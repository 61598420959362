import { createSlice } from "@reduxjs/toolkit";

const insiderSlice = createSlice({
  name: "selectedRow",
  initialState: {
    selectedRow: 0,
  },
  reducers: {
    selectRow: (state, { payload }) => {
      state.selectedRow = payload;
    },
  },
});

const { actions, reducer } = insiderSlice;

export const { selectRow } = actions;

export default reducer;
