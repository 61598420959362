import { Flex } from "antd";
import OutlineButton from "../../../ui-elements/OutlineButton";
import graphIcon from "../../../../assets/images/graph-icon.svg";
import checkIcon from "../../../../assets/images/check-icon.png";
import "./styles.scss";
import { Plan, Response } from "../../../../types";
import { useCreateCheckoutMutation } from "../../../../services/subscription";
import { useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../hooks";
import { CheckoutMode } from "../../../../enums";
import { scrollToDiv } from "../../../../utils";

interface PricingCardProps {
  plan: Plan;
}

const PricingCard = ({ plan }: PricingCardProps) => {
  const { errorToast } = useToast();
  const navigate = useNavigate();
  const { getToken, signOut, isSignedIn } = useAuth();
  const [createCheckout, { isLoading, isError, error, data }] = useCreateCheckoutMutation();
  const { name, offerings } = plan;
  const { price = 0, interval, priceId } = plan.activePrice || {};

  const selectPlan = async () => {
    if (isSignedIn && price !== "Custom") {
      const token = await getToken();
      if (token && priceId) {
        createCheckout({ token, priceId, mode: price === 0 ? CheckoutMode.PAYMENT : CheckoutMode.SUBSCRIPTION });
      }
    } else if (isSignedIn && price === "Custom") {
      navigate("/feedback");
      setTimeout(() => {
        scrollToDiv("feedback-form");
      }, 500);
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (isError && error) {
      const fetchBaseQueryError = error as FetchBaseQueryError;
      if (fetchBaseQueryError.data) {
        const { message } = fetchBaseQueryError.data as Response;
        errorToast("Error", message || "Something went wrong");
      } else {
        errorToast("Error", "Something went wrong");
      }
      if (fetchBaseQueryError.status === 401) signOut();
    }
  }, [isError, error]);

  useEffect(() => {
    if (data) {
      window.location.href = data.data.url;
    }
  }, [data]);

  return (
    <Flex className="pricing-plan-card" vertical gap={30}>
      <div className="card-animation-circle"></div>
      <img src={graphIcon} width={48} alt="graph-icon" />
      <p className="pricing-plan-card-name">{name}</p>
      <Flex className="pricing-plan-card-price-container" align="center" gap={10}>
        <p className="pricing-plan-card-price-label">Price</p>
        <p className="pricing-plan-card-price">{typeof price === "number" ? `$${price}` : price}</p>
        <p className="pricing-plan-card-price-label">{interval ? `/ ${interval}` : ""}</p>
        {plan.activePrice?.interval == "year" && <p className="pricing-plan-card-name"> 10% Off </p>}
      </Flex>
      <OutlineButton className="pricing-plan-card-btn" onClick={selectPlan} loading={isLoading}>
        Select
      </OutlineButton>
      <p className="pricing-plan-card-offerings-heading">Key Offerings</p>
      {offerings.map((feature, index) => {
        return (
          <Flex key={index} className="pricing-plan-card-offerings-container" gap={10} align="center">
            <img src={checkIcon} alt="check-icon" className="check-icon" />
            <p className="pricing-plan-card-offering-name">{feature}</p>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default PricingCard;
