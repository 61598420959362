import { Flex } from "antd";
import { OutlineButton } from "../../../ui-elements";
import "./styles.scss";
import GetStartedButton from "../../GetStartedButton";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

const HeaderButtons = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  const handleLaunchApp = useCallback(() => {
    if (isSignedIn) {
      navigate("/dashboard");
    } else navigate("/login");
  }, [isSignedIn]);

  return (
    <Flex className="header-nav-btns-container" align="center" gap={10}>
      <OutlineButton onClick={handleLaunchApp}>Launch App</OutlineButton>
      <GetStartedButton />
    </Flex>
  );
};

export default HeaderButtons;
