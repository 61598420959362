import * as yup from "yup";
import { confirmPasswordValidationSchema, passwordValidationSchema } from "./commonSchemas";
import { otpFormValidationSchema } from "./otpFormSchema";

export const resetPasswordFormValidationSchema = otpFormValidationSchema.concat(
  yup.object({
    password: passwordValidationSchema,
    confirmPassword: confirmPasswordValidationSchema,
  }),
);
