import { Drawer, Flex } from "antd";
import { Dispatch, SetStateAction } from "react";
import "./styles.scss";
import { navigationLinks } from "../../../../constants";
import NavigationLinks from "../../NavigationLinks";
import HeaderButtons from "../HeaderButtons";
import crossIcon from "../../../../assets/images/cross-icon.png";
import Logo from "../../Logo";

interface HeaderMobileDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const HeaderMobileDrawer = ({ open, setOpen }: HeaderMobileDrawerProps) => {
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer height={400} placement="top" className="header-mobile-drawer" open={open} onClose={handleDrawerClose}>
      <Flex justify="space-between" align="center">
        <Logo />
        <img src={crossIcon} className="header-menu-cross-icon" onClick={handleDrawerClose} />
      </Flex>
      <NavigationLinks links={navigationLinks} setOpen={setOpen} gap={24} />
      <HeaderButtons />
    </Drawer>
  );
};

export default HeaderMobileDrawer;
