import { Layout } from "antd";
import menuIcon from "../../../assets/images/menu-icon.png";
import { navigationLinks } from "../../../constants";
import { useState } from "react";
import HeaderMobileDrawer from "./HeaderMobileDrawer";
import "./styles.scss";
import NavigationLinks from "../NavigationLinks";
import HeaderButtons from "./HeaderButtons";
import Logo from "../Logo";

const Header = () => {
  const [open, setOpen] = useState(false);
  const { Header: AntdHeader } = Layout;

  return (
    <AntdHeader className="header landing-page-section">
      <Logo />
      <div className="header-nav-container">
        <NavigationLinks links={navigationLinks} vertical={false} />
      </div>
      <div className="header-nav-btns">
        <HeaderButtons />
      </div>
      <img src={menuIcon} className="header-menu-icon" onClick={() => setOpen(!open)} />
      <HeaderMobileDrawer open={open} setOpen={setOpen} />
    </AntdHeader>
  );
};

export default Header;
