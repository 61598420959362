// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
  .plans-modal .ant-modal-content {
    overflow: auto;
    max-height: 700px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/PlansModal/styles.scss"],"names":[],"mappings":"AAEA;EAEI;IACE,cAAA;IACA,iBAAA;EAFJ;AACF","sourcesContent":["@import \"../../../styles/variables\";\n\n@media screen and (max-width: $md-breakpoint) {\n  .plans-modal {\n    .ant-modal-content {\n      overflow: auto;\n      max-height: 700px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
