import { Flex } from "antd";
import { SectionHeader } from "../../../common";
import { Formik, FormikHelpers } from "formik";
import { Button, Input, TextArea } from "../../../ui-elements";
import ErrorText from "../../../common/ErrorText";
import "./styles.scss";
import { useState } from "react";
import { feedbackTypes } from "../../../../constants";
import emailjs from "@emailjs/browser";
import { feedbackFormSchema } from "../../../../validation-schemas";
import { useToast } from "../../../../hooks";

interface FeedbackFormProps {
  showTitle: boolean;
}

const FeedbackForm = ({ showTitle }: FeedbackFormProps) => {
  const { successToast, errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackType, setFeedbackType] = useState<"feedback" | "feature">("feedback");

  const handleClick = (type: string) => {
    setFeedbackType(type as unknown as "feedback" | "feature");
  };

  const initialValues = {
    name: "",
    email: "",
    description: "",
  };

  const sendEmail = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    try {
      setIsLoading(true);
      const { resetForm } = actions;
      if (process.env.REACT_APP_EMAIL_JS_SERVICE_ID && process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID) {
        await emailjs.send(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, values, {
          publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
        });
      }
      successToast("Feedback Sent", "Your feedback has been successfully submitted.");
      resetForm();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      errorToast("Feedback Failed", error.text || "An error occurred while submitting your feedback.");
    }
  };

  return (
    <Flex id="feedback-form" className={`feedback-form-section ${showTitle ? "section-container" : ""}`} vertical gap={60}>
      {showTitle && (
        <SectionHeader
          title="Submit feedback or feature"
          description="Discover how Blockspy has empowered individuals and businesses in their blockchain endeavors."
          className="feedback-form-section-header"
        >
          <Flex className="feedback-switch-container" justify="space-between" align="center">
            {feedbackTypes.map((type, index) => {
              return (
                <p
                  className={`feedback-switch-label ${feedbackType === type ? "feedback-switch-label-active" : ""}`}
                  key={index}
                  onClick={() => handleClick(type)}
                >
                  {type}
                </p>
              );
            })}
          </Flex>
        </SectionHeader>
      )}
      <Formik initialValues={initialValues} validationSchema={feedbackFormSchema} onSubmit={sendEmail}>
        {({ handleSubmit, handleChange, errors, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Flex className="feedback-form-section-fields-container" vertical gap={24}>
              <Flex className="feedback-form-section-fields" gap={12} justify="space-between">
                <Flex vertical style={{ width: "100%" }}>
                  <Input
                    name="name"
                    status={errors.name && touched.name ? "error" : ""}
                    placeholder="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                  {errors.name && touched.name && <ErrorText text={errors.name} />}
                </Flex>
                <Flex vertical style={{ width: "100%" }}>
                  <Input
                    name="email"
                    status={errors.email && touched.email ? "error" : ""}
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  {errors.email && touched.email && <ErrorText text={errors.email} />}
                </Flex>
              </Flex>
              <Flex className="text-area-with-attachment" vertical>
                <TextArea
                  name="description"
                  status={errors.description && touched.description ? "error" : ""}
                  placeholder="Description"
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  value={values.description}
                  onChange={handleChange}
                />
                {/* <Upload>
                  <div className="text-area-attachment">Attachments</div>
                </Upload> */}
              </Flex>

              {errors.description && touched.description && <ErrorText text={errors.description} />}
              <Button htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
    </Flex>
  );
};

FeedbackForm.defaultProps = {
  showTitle: false,
};

export default FeedbackForm;
