import { Flex, Spin } from "antd";
import PricingCard from "../../components/common/PricingSection/PricingCard";
import { useGetPlansQuery } from "../../services/plan";
import { Switch } from "../../components/ui-elements";
import { useState } from "react";
import { PlanInterval } from "../../enums";
import { useMediaQuery } from "../../hooks";
import { useUser } from "@clerk/clerk-react";

const DashboardPricing = () => {
  const { user } = useUser();
  const planType = user?.publicMetadata.planType || "Scout";
  const [interval, setInterval] = useState(PlanInterval.MONTHLY);
  const desktop = useMediaQuery("(max-width: 1024px)");
  const { isLoading, data } = useGetPlansQuery({ interval });

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      setInterval(PlanInterval.YEARLY);
    } else {
      setInterval(PlanInterval.MONTHLY);
    }
  };
  return (
    <Flex vertical gap={20}>
      <Flex className="pricing-plan-switch-container" justify="center" align="center" gap={10}>
        <p className="pricing-plan-switch-label">Monthly</p>
        <Switch onChange={handleSwitchChange} />
        <p className="pricing-plan-switch-label">Yearly</p>
      </Flex>
      <Flex wrap="wrap" justify={desktop ? "center" : "left"} gap={20}>
        {" "}
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <Spin />
          </div>
        ) : (
          data?.data.data
            .filter(plan => plan.name !== planType)
            .map((plan, index) => {
              return <PricingCard key={index} plan={plan} />;
            })
        )}
      </Flex>
    </Flex>
  );
};

export default DashboardPricing;
