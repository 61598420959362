import { Flex } from "antd";
import { Button, Modal } from "../../ui-elements";
import Cookies from "js-cookie";
import "./styles.scss";

interface DisclaimerModalProps {
  open: boolean;
  handleClose: () => void;
}

const DisclaimerModal = ({ open, handleClose }: DisclaimerModalProps) => {
  const handleAgree = () => {
    Cookies.set("ToS", "true");
    handleClose();
  };

  return (
    <Modal
      className="disclaimer-modal"
      title="Legal Disclaimer"
      width="50rem"
      centered
      open={open}
      closable={false}
      onCancel={handleClose}
      maskClosable={false}
      footer={null}
    >
      <p
        className="disclaimer-text"
        dangerouslySetInnerHTML={{
          __html: `
Please read the following disclaimer carefully before using Blockspy ("the Service").<br>
<span class="disclaimer-title">1. No Financial Advice Provided</span><br>
The information provided by Blockspy through its website, applications, or any other platform is 
for general informational purposes only. All information on the Service is provided in good faith, 
however, we make no representation or warranty of any kind, express or implied, regarding the 
accuracy, adequacy, validity, reliability, availability, or completeness of any information on the 
Service.<br>
Under no circumstance shall Blockspy have any liability to you for any loss or damage of any 
kind incurred as a result of the use of the Service or reliance on any information provided on the
Service. Your use of the Service and your reliance on any information on the Service is solely at
your own risk.<br>
<span class="disclaimer-title">2. Not Qualified to Provide Financial Advice</span><br>
Blockspy is not a financial advisor, nor is it qualified to give financial advice. The contents of the 
Service, such as text, graphics, images, and other material contained on the website ("Content")
are for informational purposes only and do not constitute financial advice, legal advice, or any 
other type of advice. Blockspy does not recommend that any cryptocurrency should be bought, 
earned, sold, or held by you. Do conduct your own due diligence and consult your financial 
advisor before making any investment decisions.<br>
<span class="disclaimer-title">3. For Educational and Informational Purposes Only</span><br>
The Service is intended to provide insights and understanding of blockchain data only. It is not 
intended to provide specific financial advice or to take the place of professional financial advice 
or service. The insights derived from the Service should not be interpreted as a 
recommendation for any specific course of action.<br>
<span class="disclaimer-title">4. Investment Risks</span><br>
Investing in cryptocurrencies is highly speculative and involves a high degree of risk. Markets 
for cryptocurrencies are volatile and can fluctuate widely. You should be prepared for the 
possibility of losing your entire investment.<br>
By using Blockspy, you acknowledge and agree that you have read, understood, and agree to 
be bound by this disclaimer. If you do not agree with the terms of this disclaimer, you are 
advised not to use the Service.`,
        }}
      />
      <Flex justify="end">
        <Button onClick={handleAgree}>I Agree</Button>
      </Flex>
    </Modal>
  );
};

export default DisclaimerModal;
