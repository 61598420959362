import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebarOpen",
  initialState: {
    sidebarOpen: false,
  },
  reducers: {
    toggleSidebar: state => {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },
});

const { actions, reducer } = sidebarSlice;

export const { toggleSidebar } = actions;

export default reducer;
