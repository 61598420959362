import { Flex, Spin } from "antd";
import "./styles.scss";
import Switch from "../../ui-elements/Switch";
import { useState } from "react";
import SectionHeader from "../SectionHeader";
import PricingCard from "./PricingCard";
import { useGetPlansQuery } from "../../../services/plan";
import { PlanInterval } from "../../../enums";
import { useUser } from "@clerk/clerk-react";

const PricingSection = () => {
  const { user } = useUser();
  const [interval, setInterval] = useState(PlanInterval.MONTHLY);
  const { isLoading, data } = useGetPlansQuery({ interval });
  const planType = user?.publicMetadata.planType || "Scout";

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      setInterval(PlanInterval.YEARLY);
    } else {
      setInterval(PlanInterval.MONTHLY);
    }
  };

  return (
    <Flex id="pricing" className="section-container pricing-section" vertical align="center" gap={60}>
      <SectionHeader
        title="Flexible Pricing Plans"
        description="Blockspy offers flexible pricing plans designed to cater to a wide range of users, from individual enthusiasts to enterprise-level solutions."
        className="pricing-section-header"
      >
        <Flex className="pricing-plan-switch-container" justify="center" align="center" gap={10}>
          <p className="pricing-plan-switch-label">Monthly</p>
          <Switch onChange={handleSwitchChange} />
          <p className="pricing-plan-switch-label">Yearly</p>
        </Flex>
      </SectionHeader>
      {isLoading ? (
        <Spin />
      ) : (
        <div className="pricing-card-container">
          {data?.data.data
            .filter(plan => plan.name !== planType)
            .map((plan, index) => {
              return <PricingCard key={index} plan={plan} />;
            })}
        </div>
      )}
    </Flex>
  );
};

export default PricingSection;
