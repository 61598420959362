export const ideas = [
  {
    title: "Propose an Idea",
    description: "Describe your idea or feature request in detail. What problem does it solve? How can it benefit the community?",
  },
  {
    title: "Engage with the Community",
    description:
      "Engage in discussions with fellow users. Provide feedback, offer suggestions, and explore opportunities for collaboration.",
  },
  {
    title: "Vote for Your Favorites",
    description: "Vote for the ideas you believe have the most potential. Your votes help prioritize the development roadmap.",
  },
];
