import { Flex } from "antd";
import LoginForm from "../../components/screens/Login/LoginForm";
import authImg from "../../assets/images/auth-bg-img.png";

const Login = () => {
  return (
    <Flex justify="space-between" gap={40}>
      <LoginForm />
      <Flex className="auth-bg-img" vertical justify="center">
        <img src={authImg} />
      </Flex>
    </Flex>
  );
};

export default Login;
