import openSeaLogo from "../assets/images/opensea-logo.png";
import avalancheLogo from "../assets/images/avalanche-logo.png";
import baseLogo from "../assets/images/base-logo.png";
import abritrumLogo from "../assets/images/abritrum-logo.png";
import celoLogo from "../assets/images/celo-logo.png";
import chainlinkLogo from "../assets/images/chainlink-logo.png";
import ethereumLogo from "../assets/images/ethereum-logo.png";
import fantomLogo from "../assets/images/fantom-logo.png";
import optimismLogo from "../assets/images/optimism-logo.png";
import coinbaseLogo from "../assets/images/coinbase-logo.png";
import polygonLogo from "../assets/images/polygon-logo.png";

export const clients = [
  openSeaLogo,
  avalancheLogo,
  baseLogo,
  abritrumLogo,
  celoLogo,
  chainlinkLogo,
  ethereumLogo,
  fantomLogo,
  optimismLogo,
  coinbaseLogo,
  polygonLogo,
];
