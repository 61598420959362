import { ReactNode } from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import "./styles.scss";

const UserLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="user-layout">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default UserLayout;
