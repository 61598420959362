import { DashboardPricing, Feedback, ForgetPassword, Help, Home, Ideas, Insiders, Login, Settings, Signup } from "../pages";

export const authRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
  },
];

export const dashboardRoutes = [
  {
    path: "/dashboard/:tableName?",
    component: Insiders,
  },
  {
    path: "/pricing-plans",
    component: DashboardPricing,
  },
  {
    path: "/ideas",
    component: Ideas,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/help",
    component: Help,
  },
];

export const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/feedback",
    component: Feedback,
  },
];
