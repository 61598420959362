import { Divider } from "antd";
import DashboardContentSection from "../../components/common/DashboardContentSection";
import "./styles.scss";
import { NotificationsSettings, PaymentSettings, EmailSettings, PasswordSettings } from "../../components/screens/Settings";
import { LogoutButton } from "../../components/common";

const Settings = () => {
  return (
    <div className="settings-page">
      <DashboardContentSection title="Email">
        <EmailSettings />
        <Divider className="divider" />
        <PasswordSettings />
        <Divider className="divider" />
        <NotificationsSettings />
        <Divider className="divider" />
        <PaymentSettings />
        <LogoutButton />
      </DashboardContentSection>
    </div>
  );
};

export default Settings;
