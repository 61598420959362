import { DashboardContentSection } from "../../components/common";
import { FeedbackForm } from "../../components/screens/Feedback";

const Help = () => {
  return (
    <DashboardContentSection title="help">
      <FeedbackForm />
    </DashboardContentSection>
  );
};
export default Help;
