import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface ReturnType {
  data: { url: string };
}

interface PayloadType {
  priceId: string;
  mode: string;
  token: string;
}

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
  }),
  endpoints: builder => ({
    createCheckout: builder.mutation<ReturnType, PayloadType>({
      query: ({ priceId, mode, token }) => {
        return {
          url: "subscription/create-checkout",
          method: "POST",
          body: {
            priceId,
            mode,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    cancelSubscription: builder.mutation<ReturnType, string>({
      query: token => {
        return {
          url: "subscription/cancel",
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    activateSubscription: builder.mutation<ReturnType, string>({
      query: token => {
        return {
          url: "subscription/activate",
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useCreateCheckoutMutation, useCancelSubscriptionMutation, useActivateSubscriptionMutation } = subscriptionApi;
