// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer-modal .disclaimer-title {
  color: white;
  font-weight: 600;
}
.disclaimer-modal .disclaimer-text {
  color: #cbd5e0 !important;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .disclaimer-modal .ant-modal-content {
    overflow: auto;
    max-height: 700px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/DisclaimerModal/styles.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,gBAAA;AAFJ;AAIE;EACE,yBAAA;EACA,mBAAA;AAFJ;AAIE;EACE;IACE,cAAA;IACA,iBAAA;EAFJ;AACF","sourcesContent":["@import \"../../../styles/variables\";\n\n.disclaimer-modal {\n  .disclaimer-title {\n    color: white;\n    font-weight: 600;\n  }\n  .disclaimer-text {\n    color: $gray-400 !important;\n    text-align: justify;\n  }\n  @media screen and (max-width: $md-breakpoint) {\n    .ant-modal-content {\n      overflow: auto;\n      max-height: 700px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
