const scrollToTop = () => {
  window.scroll({ top: 0, behavior: "smooth" });
};

const scrollToDiv = (id: string) => {
  const targetDiv = document.getElementById(id);
  if (targetDiv) targetDiv.scrollIntoView({ behavior: "smooth" });
};

export { scrollToTop, scrollToDiv };
