import { Flex } from "antd";
import { Switch } from "../../../ui-elements";
import "./styles.scss";

const NotificationsSettings = () => {
  return (
    <Flex vertical gap={24}>
      <p className="dashboard-content-section-title">Notification Preferences</p>
      <Flex className="notification-settings-container" justify="space-between" align="center">
        <p className="notification-settings-container-title">Email Notifications</p>
        <Switch />
      </Flex>
    </Flex>
  );
};

export default NotificationsSettings;
