import tiktokIcon from "../assets/images/tiktok-icon.svg";
import facebookIcon from "../assets/images/facebook-icon.svg";
import twitterIcon from "../assets/images/twitter-icon.svg";
import instagramIcon from "../assets/images/instagram-icon.svg";

export const navigationLinks = [
  {
    name: "Home",
    path: "/",
    type: "link",
  },
  {
    name: "Features",
    id: "features",
    type: "scroll",
    path: "#features",
  },
  {
    name: "About Us",
    id: "about",
    type: "scroll",
    path: "#about",
  },
  {
    name: "Pricing",
    id: "pricing",
    type: "scroll",
    path: "#pricing",
  },
  {
    name: "Feedback",
    type: "link",
    path: "/feedback",
  },
  {
    name: "Terms of service",
    type: "modal",
    path: "#",
  },
];

export const socialLinks = [
  {
    name: "Tiktok",
    path: "https://www.tiktok.com",
    type: "redirect",
    icon: tiktokIcon,
  },
  {
    name: "Facebook",
    path: "https://www.facebook.com",
    type: "redirect",
    icon: facebookIcon,
  },
  {
    name: "Twitter",
    path: "https://www.twitter.com",
    type: "redirect",
    icon: twitterIcon,
  },
  {
    name: "Instagram",
    path: "https://www.instagram.com",
    type: "redirect",
    icon: instagramIcon,
  },
];
