import { Checkbox as AntdCheckbox, CheckboxProps } from "antd";
import { ReactNode } from "react";
import "./styles.scss";

interface Props extends CheckboxProps {
  children?: ReactNode;
}

const Checkbox = (props: Props) => {
  const { children, ...checkboxProps } = props;
  return (
    <AntdCheckbox className="custom-checkbox" {...checkboxProps}>
      {children}
    </AntdCheckbox>
  );
};

export default Checkbox;
