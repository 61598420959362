import { Flex } from "antd";
import { motion } from "framer-motion";
import { features } from "../../../../constants";
import { GetStartedButton, SectionHeader } from "../../../common";
import FeatureCard from "./FeatureCard";
import "./styles.scss";

const FeaturesSection = () => {
  return (
    <Flex id="features" className="section-container features-section" align="center" vertical gap={60}>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }}>
        <SectionHeader
          title="We specialize where others generalize"
          description="Secret agents trained in uncovering gems hidden in plain sight"
        />
      </motion.div>
      <Flex vertical gap={24}>
        <Flex className="features-section-cards" gap={24}>
          {features.slice(0, 3).map((feature, index) => {
            return <FeatureCard key={index} title={feature.title} description={feature.description} />;
          })}
        </Flex>
        <Flex className="features-section-cards" justify="space-between" gap={24}>
          {features.slice(3, features.length).map((feature, index) => {
            return <FeatureCard key={index} title={feature.title} description={feature.description} />;
          })}
        </Flex>
      </Flex>
      <GetStartedButton />
    </Flex>
  );
};

export default FeaturesSection;
