import { useNavigate } from "react-router-dom";
import { Button } from "../../ui-elements";
import { useAuth } from "@clerk/clerk-react";
import { useCallback } from "react";

interface GetStartedButtonProps {
  text?: string;
  className?: string;
}

const GetStartedButton = ({ text, className }: GetStartedButtonProps) => {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  const handleNavigation = useCallback(() => {
    if (isSignedIn) {
      navigate("/dashboard");
    } else navigate("/signUp");
  }, [isSignedIn]);

  return (
    <Button className={className} onClick={handleNavigation}>
      {text || "Get Started"}
    </Button>
  );
};

export default GetStartedButton;
