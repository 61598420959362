import { Table, TableProps, Select, Input, Button } from "antd";
import "./styles.scss";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { Modal } from "../../../ui-elements";
import { selectRow } from "../../../../slices/insider.slice";
import { Insider, SpyType, FiltersType } from "../../../../types";
import { InsiderGraph } from "../../../common";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../../slices";
import { useParams } from "react-router-dom";
import filterIcon from "../../../../assets/images/FilterIcon.svg";
import { useRef } from "react";
import { filtersInitialState } from "../../../../constants/filter";
interface Props {
  columns: TableProps<Insider>["columns"];
  loading?: TableProps<Insider>["loading"];
  selectRow: (index: number) => void;
  currentPage: number;
  selectedSpy: SpyType;
  dataSource: Insider[];
  dataCount: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
  onFiltersChange?: (filters: FiltersType) => void;
}

const InsidersTable = (props: Props) => {
  const { dataSource, dataCount, currentPage, selectRow, selectedSpy, setCurrentPage, loading, columns } = props;
  const [open, setOpen] = useState(false);
  const { tableName } = useParams();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const { Option } = Select;
  const filterIconRef = useRef<HTMLButtonElement>(null);
  const { onFiltersChange } = props;
  const [filters, setFilters] = useState(filtersInitialState);
  const showIframe = useMemo(() => {
    if (selectedSpy && selectedSpy?.iframe) return selectedSpy && selectedSpy?.iframe;
    else if (tableName?.includes("NFT")) return false;
    else return true;
  }, [selectedSpy]);

  const handleRowClick = (index: number) => {
    if (!showIframe) return;
    selectRow(index || 0);
    setOpen(true);
  };

  const applyFiltersOnBackend = () => {
    if (onFiltersChange) {
      onFiltersChange(filters);
      setOpenFilterModal(false);
    }
  };
  const onReset = () => {
    localStorage.removeItem("savedFilters");
    if (onFiltersChange) {
      onFiltersChange(filtersInitialState);
      setOpenFilterModal(false);
    }
  };

  useEffect(() => {
    if (Object.values(filters).some(value => value !== "")) {
      localStorage.setItem("savedFilters", JSON.stringify(filters));
    }
  }, [filters]);

  useEffect(() => {
    const savedFilters = localStorage.getItem("savedFilters");
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
  }, []);
  useEffect(() => {
    const existingTokenSymbolsJSON = localStorage.getItem("tokenSymbols");
    let existingTokenSymbols = [];

    if (existingTokenSymbolsJSON) {
      existingTokenSymbols = JSON.parse(existingTokenSymbolsJSON);
    }
    const newTokenSymbols = dataSource.map(record => record.token_symbol).filter((value, index, self) => self.indexOf(value) === index);

    const mergedTokenSymbols = Array.from(new Set([...existingTokenSymbols, ...newTokenSymbols]));

    localStorage.setItem("tokenSymbols", JSON.stringify(mergedTokenSymbols));
  }, [dataSource]);

  useEffect(() => {
    const existingBlockchainDataJSON = localStorage.getItem("blockchainData");
    let existingBlockchainData = [];

    if (existingBlockchainDataJSON) {
      existingBlockchainData = JSON.parse(existingBlockchainDataJSON);
    }
    const newBlockchainData = dataSource.map(block => block.blockchain).filter((value, index, self) => self.indexOf(value) === index);

    const mergedBlockchainData = Array.from(new Set([...existingBlockchainData, ...newBlockchainData]));

    localStorage.setItem("blockchainData", JSON.stringify(mergedBlockchainData));
  }, [dataSource]);

  return (
    <div className="insiders-table-container">
      <span
        className="filterIcon"
        onClick={() => {
          setOpenFilterModal(true);
        }}
        ref={filterIconRef}
      >
        <img src={filterIcon} alt="Filters" />
      </span>
      <Table
        className="insiders-table"
        pagination={false}
        showSorterTooltip={true}
        loading={loading}
        onRow={(_, index) => {
          return {
            onClick: () => handleRowClick(index || 0),
          };
        }}
        {...props}
      />
      <Modal title="Insiders" centered open={open} onCancel={() => setOpen(false)} width="100%" footer={null} destroyOnClose>
        <InsiderGraph data={dataSource} dataCount={dataCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </Modal>
      <Modal
        className="custom-modals"
        title="Filters"
        centered
        visible={openFilterModal}
        onCancel={() => setOpenFilterModal(false)}
        footer={null}
        style={{
          position: "absolute",
          top: filterIconRef.current ? filterIconRef.current.getBoundingClientRect().bottom + 3 : 0,
          left: filterIconRef.current ? filterIconRef.current.getBoundingClientRect().left - 305 : 0,
          maxWidth: "350px",
        }}
      >
        <div className="solid-line"></div>

        <div className="filterSection">
          <div className="filterRow">
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                Blockchain
              </label>

              <Select
                allowClear
                className="selectorsStyle"
                placeholder="Select Here"
                value={filters.blockchain || null}
                optionFilterProp="children"
                onChange={(value: string) => setFilters({ ...filters, blockchain: value })}
              >
                {(() => {
                  const existingBlockchainDataJSON = localStorage.getItem("blockchainData");
                  let existingBlockchainData = [];

                  if (existingBlockchainDataJSON) {
                    existingBlockchainData = JSON.parse(existingBlockchainDataJSON);
                  }

                  return existingBlockchainData.map((blockchain: any) => (
                    <Option key={blockchain} value={blockchain}>
                      {blockchain}
                    </Option>
                  ));
                })()}
              </Select>
            </div>
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                Token Symbol
              </label>
              <Select
                allowClear
                value={filters.token_symbol || null}
                className="selectorsStyle"
                placeholder="Select Here"
                optionFilterProp="children"
                onChange={(value: string) => setFilters({ ...filters, token_symbol: value })}
              >
                {(() => {
                  const existingTokenSymbolsJSON = localStorage.getItem("tokenSymbols");
                  let existingTokenSymbols = [];

                  if (existingTokenSymbolsJSON) {
                    existingTokenSymbols = JSON.parse(existingTokenSymbolsJSON);
                  }

                  return existingTokenSymbols.map((token_symbol: any) => (
                    <Option key={token_symbol} value={token_symbol}>
                      {token_symbol}
                    </Option>
                  ));
                })()}
              </Select>
            </div>
          </div>

          <div className="filterRow">
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                Net USD Flow
              </label>
              <Input
                className="inputStyle , placeholder-color"
                placeholder="From"
                value={filters.net_flow_usd_from}
                onChange={e => setFilters({ ...filters, net_flow_usd_from: e.target.value })}
              />
            </div>
            <div className="filterFiledWithoutLable">
              <Input
                className="inputStyle , placeholder-color"
                placeholder="To"
                value={filters.net_flow_usd_to}
                onChange={e => setFilters({ ...filters, net_flow_usd_to: e.target.value })}
              />
            </div>
          </div>

          <div className="filterRow">
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                USD Buy
              </label>
              <Input
                className="inputStyle , placeholder-color"
                placeholder="From"
                value={filters.buy_amount_usd_from}
                onChange={e => setFilters({ ...filters, buy_amount_usd_from: e.target.value })}
              />
            </div>
            <div className="filterFiledWithoutLable">
              <Input
                className="inputStyle , placeholder-color"
                placeholder="To"
                value={filters.buy_amount_usd_to}
                onChange={e => setFilters({ ...filters, buy_amount_usd_to: e.target.value })}
              />
            </div>
          </div>

          <div className="filterRow">
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                USD Selles
              </label>
              <Input
                className="inputStyle , placeholder-color"
                placeholder="From"
                value={filters.sell_amount_usd_from}
                onChange={e => setFilters({ ...filters, sell_amount_usd_from: e.target.value })}
              />
            </div>
            <div className="filterFiledWithoutLable">
              <Input
                className="inputStyle , placeholder-color"
                placeholder="To"
                value={filters.sell_amount_usd_to}
                onChange={e => setFilters({ ...filters, sell_amount_usd_to: e.target.value })}
              />
            </div>
          </div>

          <div className="filterRow">
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                Buyers
              </label>
              <Input
                className="inputStyle , placeholder-color"
                placeholder="Type Here"
                value={filters.buyers}
                onChange={e => setFilters({ ...filters, buyers: e.target.value })}
              />
            </div>
            <div className="filterFiled">
              <label htmlFor="blockchain-select" className="lableStyle">
                Sellers
              </label>
              <Input
                className="inputStyle , placeholder-color"
                placeholder="Type Here"
                value={filters.sellers}
                onChange={e => setFilters({ ...filters, sellers: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="buttonDiv">
            <Button className="buttonStyle" onClick={applyFiltersOnBackend}>
              Filter Now
            </Button>
          </div>
          <div className="buttonDiv">
            <Button className="buttonStyle" onClick={onReset}>
              Reset
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    selectedRow: state.insiders.selectedRow,
    selectedSpy: state.spies.selectedSpy as SpyType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      selectRow,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InsidersTable);
