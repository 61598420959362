import { Flex } from "antd";
import { clients } from "../../../constants";
import "./styles.scss";

const ClientsSection = () => {
  return (
    <div className="section-container">
      <Flex className="clients-section" justify="center" align="center" wrap="wrap" gap={60}>
        {clients.map((client, index) => {
          return <img key={index} src={client} className="client-logo" alt="client-logo" />;
        })}
      </Flex>
    </div>
  );
};

export default ClientsSection;
