import { Flex } from "antd";
import logoutIcon from "../../../assets/images/logout-icon.png";
import "./styles.scss";
import { SignOutButton } from "@clerk/clerk-react";

const LogoutButton = () => {
  return (
    <SignOutButton>
      <Flex className="logout-btn" gap={12} justify="center" align="center">
        <p>Log Out</p>
        <img src={logoutIcon} />
      </Flex>
    </SignOutButton>
  );
};

export default LogoutButton;
