import { Flex } from "antd";
import { motion } from "framer-motion";
import whoWeAreImg from "../../..//assets/images/who-we-are-img.svg";
import GetStartedButton from "../GetStartedButton";
import "./styles.scss";

interface WhoWeAreSectionProps {
  description: string;
}

const WhoWeAreSection = ({ description }: WhoWeAreSectionProps) => {
  return (
    <Flex id="about" className="section-container who-we-are-section" align="center">
      <Flex className="section-left-column who-we-are-section-text" vertical gap={15}>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} viewport={{ once: true }}>
            <h1 className="section-title">Who We Are</h1>
          </motion.div>
        </motion.div>
        <p className="section-description">{description}</p>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <GetStartedButton className="section-get-started-btn" />
        </motion.div>
      </Flex>
      <Flex className="section-right-column" vertical>
        <motion.div
          initial={{ scale: 0.2, opacity: 0, transformOrigin: "left center" }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <img src={whoWeAreImg} alt="who-we-are-img" style={{ width: "100%" }} />
        </motion.div>
      </Flex>
    </Flex>
  );
};

export default WhoWeAreSection;
