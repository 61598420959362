import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { ClerkProvider } from "@clerk/clerk-react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || "";
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Provider store={store}>
        <App />
      </Provider>
    </ClerkProvider>
  </React.StrictMode>,
);
