import { Flex, Spin } from "antd";
import { Insider } from "../../../types";
import { RootState } from "../../../slices";
import { connect } from "react-redux";
import InsiderGraphDataCard from "../InsiderGraphDataCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { scrollToDiv } from "../../../utils";
import { useMediaQuery } from "../../../hooks";
import { DEFAULT_PAGE_SIZE } from "../../../services/insider";

interface InsiderGraphProps {
  title?: string;
  data: Insider[];
  currentPage: number;
  dataCount: number;
  selectedRow: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
}

const InsiderGraph = ({ title, data, dataCount, currentPage, selectedRow, setCurrentPage }: InsiderGraphProps) => {
  const tablet = useMediaQuery("(max-width: 768px)");
  const [items, setItems] = useState<Insider[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const isInitialRender = useRef(true);

  const totalCount = useMemo(() => {
    return dataCount - (currentPage - 1) * DEFAULT_PAGE_SIZE;
  }, [dataCount]);

  const isLastPage = useMemo(() => {
    return currentPage === Math.ceil(dataCount / DEFAULT_PAGE_SIZE);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToDiv(`insider-graph-data-card-${selectedRow}`);
    }, 1000);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedRow]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setItems(prevState => [...prevState, ...data]);
  }, [data]);

  useEffect(() => {
    if (items.length >= totalCount || !data.length || isLastPage) {
      setHasMore(false);
    }
  }, []);

  const fetchMoreData = () => {
    if (items.length >= totalCount || !data.length) {
      setHasMore(false);
      return;
    }
    setCurrentPage && setCurrentPage(prevState => prevState + 1);
  };

  return (
    <Flex gap={24} className="insider-graph" align="flex-start">
      <Flex className="insider-graph-data-container" vertical gap={12}>
        <p className="insider-graph-data-container-title">{title || "Tokens being bought"}</p>
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          className="insider-graph-data-container-scroll"
          height={tablet ? "100%" : "730px"}
          loader={
            <Flex justify="center">
              <Spin />
            </Flex>
          }
        >
          <Flex className="insider-graph-data-container-tokens" vertical gap={12}>
            {items.map((insider, index) => (
              <InsiderGraphDataCard insider={insider} key={index} index={index} />
            ))}
          </Flex>
        </InfiniteScroll>
      </Flex>
      <div style={{ flex: 1 }}>
        <Spin size="large" spinning={isLoading}>
          <div dangerouslySetInnerHTML={{ __html: items[selectedRow]?.chartiFrame }} style={{ height: "768px" }}></div>
        </Spin>
      </div>
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedRow: state.insiders.selectedRow,
  };
};

export default connect(mapStateToProps)(InsiderGraph);
