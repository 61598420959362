import { Flex } from "antd";
import "./styles.scss";

interface HowItWorksCardProps {
  title: string;
  description: string;
  index: number;
}

const HowItWorksCard = ({ title, description, index }: HowItWorksCardProps) => {
  return (
    <Flex vertical className="how-it-works-card" gap={24}>
      <Flex className="how-it-works-card-index" justify="center" align="center">
        <p className="how-it-works-card-title">0{index + 1}</p>
      </Flex>
      <p className="how-it-works-card-title">{title}</p>
      <p className="how-it-works-card-description">{description}</p>
    </Flex>
  );
};

export default HowItWorksCard;
