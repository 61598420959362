import { Flex } from "antd";
import { OutlineButton } from "../../../ui-elements";
import { useCallback, useEffect, useState } from "react";
import { PlansModal } from "../../../common";
import "./styles.scss";
import { useAuth, useUser } from "@clerk/clerk-react";
import { useActivateSubscriptionMutation, useCancelSubscriptionMutation } from "../../../../services/subscription";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useToast } from "../../../../hooks";
import { Response } from "../../../../types";
import { Plan } from "../../../../enums";

const PaymentSettings = () => {
  const [
    activateSubscription,
    {
      isLoading: activateSubscriptionLoading,
      isError: isActivateSubscriptionError,
      error: activateSubscriptionError,
      isSuccess: isActivateSubscriptionSuccess,
    },
  ] = useActivateSubscriptionMutation();
  const [cancelSubscription, { isLoading, isError, error, isSuccess }] = useCancelSubscriptionMutation();
  const { successToast, errorToast } = useToast();
  const { signOut, getToken } = useAuth();
  const { user } = useUser();
  const { planType, cancelAtPeriodEnd } = user?.publicMetadata || {};
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleActivate = useCallback(async () => {
    const token = await getToken();
    if (token) {
      activateSubscription(token);
    }
  }, []);

  const handleCancel = useCallback(async () => {
    const token = await getToken();
    if (token) {
      cancelSubscription(token);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      successToast("Success", "Your subscription has been cancelled. Please wait while we update your subscription.");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    if (isActivateSubscriptionSuccess) {
      successToast("Success", "Your subscription has been activated. Please wait while we update your subscription.");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [isSuccess, isActivateSubscriptionSuccess]);

  useEffect(() => {
    if ((isError && error) || (isActivateSubscriptionError && activateSubscriptionError)) {
      const fetchBaseQueryError = (error || activateSubscriptionError) as FetchBaseQueryError;
      if (fetchBaseQueryError.data) {
        const { message } = fetchBaseQueryError.data as Response;
        errorToast("Error", message || "Something went wrong");
      } else {
        errorToast("Error", "Something went wrong");
      }
      if (fetchBaseQueryError.status === 401) signOut();
    }
  }, [isError, error, isActivateSubscriptionError, activateSubscriptionError]);

  return (
    <Flex vertical gap={24}>
      <p className="dashboard-content-section-title">Payment</p>
      <Flex className="payment-settings-container" vertical gap={12} align="flex-start">
        {cancelAtPeriodEnd ? (
          <OutlineButton className="update-btn" onClick={handleActivate} loading={activateSubscriptionLoading}>
            Activate Plan
          </OutlineButton>
        ) : !planType || planType === Plan.SCOUT ? (
          <>
            <p className="payment-settings-container-title">Upgrade to a Premium Plan</p>
            <p className="payment-settings-container-description">
              Unlock advanced features, enhanced data access, and priority support with our premium plans.
            </p>
            <OutlineButton className="update-btn" onClick={() => setOpen(true)}>
              Update Plan
            </OutlineButton>
          </>
        ) : (
          <OutlineButton className="update-btn" onClick={handleCancel} loading={isLoading}>
            Cancel Plan
          </OutlineButton>
        )}
      </Flex>
      <PlansModal open={open} handleClose={handleClose} />
    </Flex>
  );
};

export default PaymentSettings;
