import { Flex } from "antd";
import SectionHeader from "../SectionHeader";
import "./styles.scss";
import { userReviews } from "../../../constants";
import UserReviewCard from "./UserReviewCard";

const UserReviewsSection = () => {
  return (
    <Flex className="section-container users-section" vertical align="center" gap={60}>
      <SectionHeader title="Hear from Our Users" />
      <Flex className="user-review-scroll" vertical gap={20}>
        <Flex className="flex" align="flex-start" gap={20}>
          {userReviews.map((review, index) => {
            return <UserReviewCard key={index} index={index} review={review} />;
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserReviewsSection;
