import { Flex, Spin } from "antd";
import { Modal } from "../../ui-elements";
import PricingCard from "../PricingSection/PricingCard";
import { useGetPlansQuery } from "../../../services/plan";
import { useUser } from "@clerk/clerk-react";
import "./styles.scss";
interface PlansModalProps {
  open: boolean;
  handleClose: () => void;
}

const PlansModal = ({ open, handleClose }: PlansModalProps) => {
  const { user } = useUser();
  const { isLoading, data } = useGetPlansQuery({ interval: "" });
  const planType = user?.publicMetadata.planType || "Scout";
  return (
    <Modal className="plans-modal" title="Update Plan" width="100%" centered open={open} onCancel={handleClose} footer={null}>
      <Flex wrap="wrap" gap={20}>
        {isLoading ? (
          <Spin />
        ) : (
          data?.data.data
            .filter(plan => plan.name !== planType)
            .map((plan, index) => {
              return <PricingCard key={index} plan={plan} />;
            })
        )}
      </Flex>
    </Modal>
  );
};

export default PlansModal;
