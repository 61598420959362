import { Flex } from "antd";
import "./styles.scss";

function AnimatedDivider() {
  return (
    <Flex className="section-container animated-divider" justify="space-between" align="center">
      <div className="line left-line"></div>
      <Flex justify="center" className="circle-container">
        <div className="circle animate"></div>
      </Flex>
      <div className="line right-line"></div>
    </Flex>
  );
}

export default AnimatedDivider;
