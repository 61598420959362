import { AnimatedDivider, HeroSectionDivided, ClientsSection, WhoWeAreSection, UserReviewsSection } from "../../components/common";
import aboutUsBgImg from "../../assets/images/about-us-bg-img.svg";
import MissionSection from "../../components/screens/AboutUs/MissionSection";

const AboutUs = () => {
  return (
    <>
      <div className="landing-page-section">
        <HeroSectionDivided
          title="Our Journey in Blockchain Analytics"
          description="Discover the Story Behind Blockspy"
          img={aboutUsBgImg}
        />
        <AnimatedDivider />
        <ClientsSection />
        <WhoWeAreSection description="Blockspy is created by crypto investors, for crypto investors. We understood the wealth of knowledge accessible on public blockchains and wanted to see what traders were doing. We tried many other crypto insight tools and got tired of the vague, generic “Smart Money” names and wanted granular data on exactly what insiders were buying and selling. We created Blockspy to distill these insights and offer them up to all crypto investors, beginner to expert. The insiders we track are updated with new market events, keeping you best informed with the ever-changing market." />
        <MissionSection />
      </div>
      <UserReviewsSection />
    </>
  );
};
export default AboutUs;
