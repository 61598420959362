import { Flex } from "antd";
import { ReactNode } from "react";

interface SectionHeaderProps {
  title: string;
  description?: string;
  children?: ReactNode;
  className?: string;
}

const SectionHeader = ({ title, description, children, className }: SectionHeaderProps) => {
  return (
    <Flex className={className} vertical>
      <h1 className="section-title">{title}</h1>
      <p className="section-description">{description}</p>
      {children}
    </Flex>
  );
};
export default SectionHeader;
