import { Flex } from "antd";
import "./styles.scss";

interface MissionCardProps {
  title: string;
  description: string;
}

const MissionCard = ({ title, description }: MissionCardProps) => {
  return (
    <Flex vertical className="mission-card" gap={24}>
      <p className="mission-card-title">{title}</p>
      <p className="mission-card-description">{description}</p>
    </Flex>
  );
};

export default MissionCard;
