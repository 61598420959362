import { motion } from "framer-motion";
import updateCardImg from "../../../../assets/images/update-card-img.png";
import "./styles.scss";

interface UpdateCardProps {
  title: string;
  description: string;
}

const UpdateCard = ({ title, description }: UpdateCardProps) => {
  return (
    <motion.div className="update-card" whileHover={{ height: "100%" }} transition={{ duration: 0.5 }}>
      <div className="card-animation-circle top-circle"></div>
      <img src={updateCardImg} className="update-card-img" alt="card-img" />
      <p className="update-card-date">04-01-2024</p>
      <p className="update-card-title">{title}</p>
      <p className="update-card-description">{description}</p>
      <p className="update-card-title primary-text">Read Article</p>
      <div className="card-animation-circle bottom-circle"></div>
    </motion.div>
  );
};
export default UpdateCard;
