import { Flex } from "antd";
import authImg from "../../assets/images/auth-bg-img.png";
import SignupForm from "../../components/screens/Signup/SignupForm";

const Signup = () => {
  return (
    <Flex justify="space-between" gap={40}>
      <SignupForm />
      <Flex className="auth-bg-img" vertical justify="center">
        <img src={authImg} />
      </Flex>
    </Flex>
  );
};

export default Signup;
