import { Flex, Layout } from "antd";
import { socialLinks, navigationLinks } from "../../../constants";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import mailIcon from "../../../assets/images/mail-icon.svg";
import "./styles.scss";
import NavigationLinks from "../NavigationLinks";

const Footer = () => {
  const { Footer: AntdFooter } = Layout;

  return (
    <>
      <AntdFooter className="footer">
        <Flex vertical gap={12} className="footer-logo-section">
          <Flex className="footer-logo-container" align="center" gap={10}>
            <img src={logo} className="footer-logo-img" />
            <p className="footer-logo-title">Blockspy</p>
          </Flex>
          <p className="footer-description">
            Blockspy is the industry leader in tracking profitable trendspotters On-Chain, providing you with actionable insights to help
            your research.
          </p>
        </Flex>
        <Flex className="footer-section" vertical gap={42}>
          <p className="footer-section-heading">Useful Links</p>
          <div className="footer-section-links">
            <NavigationLinks links={navigationLinks} />
          </div>
        </Flex>
        <Flex className="footer-section" vertical gap={42}>
          <p className="footer-section-heading">Social</p>
          <div className="footer-section-links">
            <NavigationLinks links={socialLinks} />
          </div>
        </Flex>
        <Flex className="footer-section" vertical gap={24}>
          <p className="footer-section-heading">Contact us</p>
          <Flex align="center" gap={10}>
            <Flex className="footer-section-icon-container" justify="center" align="center">
              <img src={mailIcon} />
            </Flex>
            <a className="footer-section-text" href="mailto:info@blockspy.ai">
              info@blockspy.ai
            </a>
          </Flex>
        </Flex>
      </AntdFooter>
      <Flex className="company-footer-section" justify="center" align="center" gap={5}>
        <p className="company-footer-section-text">Developed & Designed By</p>
        <Link to="https://hashlogics.com" target="_blank" rel="noreferrer" className="company-footer-section-link">
          Hashlogics
        </Link>
      </Flex>
    </>
  );
};

export default Footer;
